﻿/* Telekom colors (reference: https://www.brand-design.telekom.com/en/articles/colors/) */
@telekomMagenta: #E20074;
@telekomMagentaHover: #d0006b;
@telekomMagentaActive: #bc0060;
@telekomMagentaLight: #ff8dc8;
@telekomMagentaDark: #b2005c;
@telekomGrey1: #A4A4A4;
@telekomGrey2: #7c7c7c;
@telekomGrey3: #6c6c6c;
@telekomGrey4: #ededed;
@telekomGrey5: #4B4B4B;
@telekomGrey6: #d0d0d0;
@telekomGrey9: #FAFAFA;
@telekomRed: #D90000;
@telekomLink: #00739F;

/* Blue colors for neutral style */
@telekomBlue: #317CB3;
@telekomBlueLight: #53baf2;
@telekomBlueHover: @telekomBlueLight;
@telekomBlueDark: #235482;
@telekomBlueActive: @telekomBlueDark;

@colorTextActive: @telekomMagenta;
@colorTextInactive: #757575;

@backgroundFieldsLightPink: #FFC6E3;
@backgroundText: #F8F8F8;

/* Navigation */
@navigationHeight: 560px;
@navigationBackground: #808080;
@navigationNodeInactive: #bfbfbf;
@navigationNodeActive: #ff55ac;
@navigationNodeHover: @telekomMagenta;
@navigationChildNodeActive: #ff8dc8;
@navigationChildNodeHover: @navigationNodeHover;
@navigationText: @telekomGrey5;
@navigationTextHover: @navigationNodeHover;
@navigationLineActive: @navigationNodeHover;
@navigationLineHover: @navigationLineActive;
@navigationLineInactive: @navigationNodeInactive;

@highlightBoxShadow: 0 0 6px 6px @telekomMagentaLight;
@highlightTextShadow: 0 0 5px @telekomMagentaLight;

@quickAccessCutoff: 1620px;

@colorLabelForDisabledInput: silver;

@font-face {
    font-family: 'TeleGrotesk Next';
    src: url('/fonts/TeleGroteskNext-Regular.woff2') format('woff2'), url('/fonts/TeleGroteskNext-Regular.woff') format('woff');
}

@font-face {
    font-family: 'TeleGrotesk Next';
    src: url('/fonts/TeleGroteskNext-Bold.woff2') format('woff2'), url('/fonts/TeleGroteskNext-Bold.woff') format('woff');
    font-weight: bold;
}

@font-face {
    font-family: 'TeleGrotesk Next';
    src: url('/fonts/TeleGroteskNext-RegularItalic.woff2') format('woff2'), url('/fonts/TeleGroteskNext-RegularItalic.woff') format('woff');
    font-style: italic;
}

@font-face {
    font-family: 'TeleGrotesk Next';
    src: url('/fonts/TeleGroteskNext-BoldItalic.woff2') format('woff2'), url('/fonts/TeleGroteskNext-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'TeleGrotesk Next Ultra';
    src: url('/fonts/TeleGroteskNext-Ultra.woff2') format('woff2'), url('/fonts/TeleGroteskNext-Ultra.woff') format('woff');
}

@font-face {
    font-family: 'TeleIcon Outline';
    src: url('/fonts/TeleIcon-Outline.eot');
    src: url('/fonts/TeleIcon-Outline.eot?#iefix') format('embedded-opentype'), url('/fonts/TeleIcon-Outline.ttf') format('truetype');
}

@font-face {
    font-family: 'TeleIcon Solid';
    src: url('/fonts/TeleIcon-Outline.eot');
    src: url('/fonts/TeleIcon-Outline.eot?#iefix') format('embedded-opentype'), url('/fonts/TeleIcon-Solid.ttf') format('truetype');
}