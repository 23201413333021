﻿.company-section {
    background-color: rgba(191, 191, 191, 0.15);
    padding: 20px;
    margin-top: 30px;
    margin-bottom: 20px;
}
.dx-checkbox {
    padding-top: 13px;
}

.vertical-middle {
    .dx-checkbox {
        padding-top: 0px;
    }
}

#GuiThemePageInfosDataGrid {
    .dx-checkbox {
        padding-top: 0px;
    }
}