﻿@import "../_mixins.less";

.services-container {
    .nav-bubble {
        margin-bottom: 0 !important;
    }

    .nav-text {
        width: 150px;
        line-height: 1.5rem !important;
    }
}

#PanelOffers {
    display: inline-flex;
    padding: 30px 10px 15px 10px;
    width: 100%;
    overflow-y: hidden;
    overflow-x: auto;
}

.offer-panel {
    margin: 0 10px;
    width: -webkit-min-content;
    width: -moz-min-content;
    width: min-content;
    max-width: 350px;

    #PromotionTag {
        height: 75px;
        width: 100%;
        top: -25px;
        right: -35px;
        position: absolute;
    }

    .promotion-tag-container {
        background: #006699;
        height: 40px;
        min-width: 110px;
        width: auto;
        white-space: nowrap;
        position: absolute;
        right: 10px;
        top: 3px;
        -moz-box-shadow: 0px 0px 4px rgba(0,0,0,0.55);
        -khtml-box-shadow: 0px 0px 4px rgba(0,0,0,0.55);
        -webkit-box-shadow: 0px 0px 4px rgba(0,0,0,0.55);
        box-shadow: 0px 0px 4px rgba(0,0,0,0.55);
        z-index: 100;

        .promotion-tag-text {
            margin: 5px 10px;
            font-weight: bold;
            line-height: 31px;
            color: white;
            text-shadow: 1px 1px 2px rgba(0,0,0,0.2);
            text-align: center;
        }
    }

    .triangle {
        border-color: transparent transparent transparent #006699;
        border-style: solid;
        border-width: 30px;
        height: 0px;
        width: 0px;
        position: absolute;
        right: -20px;
        top: 12px;
        z-index: -1;
    }

    .offer-title {
        min-width: 270px;
        background-color: #fff;
        padding: 20px 12px 12px;
        box-shadow: 0 4px 12px 0 rgba(0,0,0,.2);
        margin: 0 12px;
        position: relative;
    }

    .product-name {
        font-weight: bold;
        width: auto;
        white-space: nowrap;
        padding-top: 5px;
    }

    .product-label {
        font-size: 26px;
        font-weight: bold;
        padding: 5px 5px 0;

        &.with-divider {
            border-right: 1px solid black;
        }
    }

    .product-cost {
        font-size: 26px;
        font-weight: bold;
        color: @telekomMagenta;
    }

    .text-container {
        width: auto;
        //white-space: nowrap;
        margin-top: -0.5rem;
    }

    /*div#StarRating {
        margin-bottom: 60px;
    }*/

    .btn-container {
        margin: 0 -12px -12px;
        font-weight: bold;
        width: 100%;
        position: absolute;
        bottom: 0;

        .select-btn {
            background-color: @telekomMagenta;
            display: inline-block;
            padding: 18px;
            height: 60px;
            line-height: 24px;
            width: 100%;
            text-align: center;
            color: white;
            text-decoration: none;

            &:hover {
                color: white;
                text-decoration: none;
                background-color: #bd0061;
            }
        }
    }

    .info-container {
        margin-top: 36px;
    }

    @offer-border-color: black;
    @offer-text-color: black;

    .offer-features {
        box-sizing: border-box;
        min-width: 270px;
        min-height: 100px;
        padding: 0 12px 12px;
        margin: 0 12px;
        background-color: #fff;
        border: 1px solid @offer-border-color;
        border-top: 0;
        margin-top: -1px;
        color: @offer-text-color;

        ul {
            width: auto;
            //white-space: nowrap;
            min-width: 100%;
            list-style: none;
            padding: 0 10px;
        }

        li {
            border-bottom: 1px solid @offer-border-color;
            margin-bottom: 12px;
            padding-bottom: 12px;
        }

        .label {
            transform: translateY(-14px);
            margin: 0 -13px 0px;
            text-align: center;

            span {
                position: relative;
                z-index: 10;
                padding: 0 18px;
                background: #fff;
            }

            &:after {
                content: "";
                width: 100%;
                height: 1px;
                position: absolute;
                left: 0;
                top: 50%;
            }
        }

        .includes-features {
            margin-top: -0.5rem;

            &:after {
                background-color: @offer-border-color;
            }
        }

        .benefits-features, .add-ons-features {
            color: @offer-text-color;

            &:after {
                background-color: @offer-border-color;
            }
        }
    }

    #AddOns {
        p {
            margin-top: 0;
            margin-bottom: 0;
        }
    }
}

/* offers slick carousel sliders */
html {
    body {
        main .offers-container .separator {
            height: 0;
        }

        main #PanelOffers {
            overflow: inherit;
            height: fit-content;
            padding: 15px 0;
            display: flex;
            
            div.slick-arrow {
                position: relative;

                span {
                    top: 50vh;
                    position: sticky;
                    display: block;
                    width: 25px;
                    height: 25px;
                    border-radius: 100%;
                    margin: 150px 10px;

                    > div {
                        width: fit-content;

                        &:hover {
                            cursor: pointer;
                        }
                    }

                    > div::before {
                        font-family: "slick";
                        font-size: 1.7rem;
                        color: #000;
                        background: #fff;
                        border-radius: 100%;
                        padding-top: 5px;
                    }
                }

                &.left-arrow {
                    margin-left: -15px;

                    div::before {
                        content: "←";
                    }
                }

                &.right-arrow {
                    margin-right: -15px;

                    div::before {
                        content: "→";
                    }
                }

                &.slick-disabled {
                    span div::before {
                        color: lightgrey;
                    }

                    span div::before:hover {
                        box-shadow: none;
                        color: lightgrey;
                        background-color: rgba(0,0,0,.6);
                        cursor: default;
                    }
                }
            }

            div.offer-panel {
                padding: 25px 25px 0;
                margin: 0;
                overflow: hidden;

                .btn-container-placeholder {
                    height: 55px;
                }

                div.offer-title,
                div.info-container {
                    margin-left: 0;
                    margin-right: 0;

                    span {
                        white-space: break-spaces;
                    }

                    div.offer-features {
                        margin-left: 0;
                        margin-right: 0;
                        width: 100%;

                        p {
                            max-width: 100%;
                            overflow: hidden;
                        }
                    }
                }

                div.info-container {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                }

                /*div.btn-container {
                    width: 100%;
                    position: absolute;
                    bottom: 0;
                }*/
            }
        }

        /* Media Queries */
        @media screen and (max-width: 768px) {
            main #PanelOffers {
                div.offer-panel {
                    div.offer-title,
                    div.info-container {
                        min-width: 200px;

                        div.offer-features {
                            padding-left: 0;
                            padding-right: 0;
                            min-width: 200px;

                            div.label {
                                width: 100%;
                                margin-left: 0;
                                margin-right: 0;
                                margin-top: 3px;
                            }

                            div:not(.label) {
                                padding: 5px;
                            }
                        }
                    }
                }

                div.right-arrow.slick-arrow > span:hover {
                    box-shadow: 0 4px 12px 0 rgba(0,0,0,.0);
                }
            } 
        }

        @media screen and (max-width: 576px) {
            main #PanelOffers {
                div.offer-panel {
                    max-width: 275px !important;

                    .promotion-tag-container {
                        right: 25px;
                    }
                    .triangle {
                        right: -5px;
                    }
                }
            }
        }

        @media screen and (min-width: 577px) {
            main #PanelOffers {
                div.offer-panel {
                    div.offer-title,
                    div.info-container {
                        div.offer-features {
                            div.label {
                                margin-top: -1px;
                            }
                        }
                    }
                }
            }
        }
    } // end of body
} // end of html