﻿@import "_definitions";

#MasterPage {
    .master-page() {
        .brand-header {
            position: relative;
            display: block;

            .bg-brand {
                background-color: @telekomMagenta;
                border-color: @telekomMagenta;
            }

            .t-logo-wrapper {
                position: absolute;
                left: 0px;
                width: 100px;
                height: 70px;
                padding-left: 40px;
                padding-top: 14px;

                .t-logo {
                    width: 50px;
                }
            }

            .app-name {
                position: absolute;
                left: 0px;
                width: 100%;
                height: 70px;
                color: white;
                padding-left: 140px;
                padding-top: 16px;
                padding-right: 140px;
                text-align: center;

                a, a:active, a:hover, a:focus, a:link, a:visited {
                    text-decoration: none;
                    color: white;
                }
            }

            .t-slogan-wrapper {
                position: absolute;
                left: 0px;
                width: 100%;
                color: white;
                padding-right: 40px;
                padding-top: 16px;
                text-align: right;
            }
        }
    }
}

#Navigation {
    .navigationPanel() {
        @media (max-width: @quickAccessCutoff) {
            #masterNavigation.QuickAccess * {
                display: none; // Hide quick access menu on smaller screens
            }
        }

        #masterNavigation {
            opacity: 0;

            &.align-center {
                width: 100%;
            }

            &.align-left {
                .nav-panel {
                    display: inline-block;
                    margin-left: -450px;
                }
            }

            &.QuickAccess {
                position: fixed;
                z-index: 10;
                width: 420px;
                bottom: 0;
                right: 0;
                transform: translate(22%, 23%) scale(0.5);

                h2 {
                    text-align: center;
                    font-size: 2.2rem;
                }

                svg {
                    position: relative;
                }

                #QuickAccessTooltipContainer {
                    z-index: 20;
                    display: none;
                    transform: translateX(-5%);
                    text-align: center;
                    pointer-events: none; // To prevent root node tooltip from flickering
                    #QuickAccessTooltip {
                        display: inline-block;
                        font-size: 2.5rem;
                        background: @telekomGrey5;
                        color: white;
                        padding: 0.5rem 3rem;
                        border-radius: 0.5rem;
                    }
                }
            }
        }

        .navigation-container {
            margin-top: 50px;
        }

        .nav-panel {
            display: block;
            position: relative;
            min-height: @navigationHeight;

            .nav-level-2 {
                display: none;
            }

            svg {
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                overflow: visible;

                circle {
                    stroke: @navigationNodeActive;
                }

                line {
                    stroke: @navigationLineActive;
                    stroke-width: 12;
                }

                a {
                    text-decoration: none;

                    line {
                        stroke: @navigationLineActive;
                        stroke-width: 12;
                    }

                    text {
                        cursor: pointer;
                        font-size: 1.25rem;
                        text-transform: uppercase;
                        fill: @navigationText;

                        &.highlighted {
                            fill: @telekomMagentaLight;
                            filter: url(#glow);
                        }
                    }

                    circle {
                        fill: white;
                        cursor: pointer;
                    }

                    image {
                        cursor: pointer;
                    }

                    &:hover, &:focus {
                        text-decoration: none;
                        outline: none;

                        circle {
                            stroke: @navigationNodeHover;
                        }

                        line {
                            stroke: @navigationLineHover;
                        }

                        text {
                            fill: @navigationTextHover;

                            &.highlighted {
                                opacity: 0.33;
                            }
                        }
                    }

                    &:active, &.active {
                        circle {
                            stroke: @navigationNodeActive;
                        }

                        line {
                            stroke: @navigationLineActive;
                        }

                        text {
                            fill: @navigationText;
                        }
                    }

                    &.disabled, &.inactive {
                        cursor: default;

                        circle {
                            stroke: @navigationNodeInactive;
                            cursor: default;
                        }

                        line {
                            stroke: @navigationLineInactive;
                            cursor: default;
                        }

                        text {
                            fill: @navigationText;
                            cursor: default;
                        }
                    }

                    &.inactive {
                        text {
                            display: none;
                        }
                    }

                    &.magenta {
                        circle, line {
                            stroke: @telekomMagenta;
                        }

                        text {
                            fill: @telekomMagenta;
                        }
                    }

                    &.root-node {
                        line {
                            stroke: @telekomMagenta;
                            stroke-width: 12;
                        }

                        circle {
                            stroke: @telekomMagenta;
                            fill: white;
                        }

                        text {
                            font-weight: bold;
                            opacity: 0;

                            // Only change the text color, not the glow color
                            &:not(.highlighted) {
                                fill: @telekomMagenta;
                            }
                        }

                        image {
                            opacity: 0;
                        }

                        // Only show root node label and icon on mouse hover
                        &:hover {
                            text:not(.highlighted) {
                                opacity: 1;
                            }

                            image {
                                opacity: 1;
                            }
                        }
                    }

                    &.togglable {
                        // Disabled for now because we don't actually have toggleable nodes
                        // right now but we DO have child nodes that are always visible.
                        // display: none;
                        circle {
                            stroke: @navigationChildNodeActive;
                        }

                        &:hover {
                            circle {
                                stroke: @navigationChildNodeHover;
                            }

                            text {
                                fill: @navigationTextHover;
                            }
                        }
                    }

                    &.nav-so-menu {
                        text {
                            tspan {
                                font-size: 1rem;
                            }
                        }
                    }
                }
            }
        }
    }
}

#SectionMaster {
    .main() {
        .left-nav-container {
            width: 100%;
            display: flex;
            justify-content: center;

            .left-nav-bubble {
                height: 60px;
                width: 60px;
                border-width: 2px;
                border-style: solid;
                border-radius: 50%;
                border-color: @telekomMagenta;
                background-repeat: no-repeat;
                background-position: center;
                background-size: 65% auto;
                background-color: white;

                @media (min-width: 768px) {
                    height: 80px;
                    width: 80px;
                    border-width: 3px;
                }

                @media (min-width: 1024px) {
                    height: 120px;
                    width: 120px;
                    border-width: 6px;
                }
            }
        }

        .main-header-container {
            display: flex;
            align-items: center;

            .icon-container {
                display: inline-flex;
                justify-content: center;
                margin-right: 20px;

                .icon-bubble {
                    height: 60px;
                    width: 60px;
                    border-width: 2px;
                    border-style: solid;
                    border-radius: 50%;
                    border-color: @telekomMagenta;
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: 75% auto;
                    background-color: white;

                    @media (min-width: 768px) {
                        height: 80px;
                        width: 80px;
                        border-width: 3px;
                    }
                }
            }

            .text-container {
                h1 {
                    border: none;
                    padding: 0;
                }
            }
        }

        .switch-buttons {
            padding-top: 4rem;
            text-align: center;

            a, button {
                margin: 10px 0px;
                min-width: 100px;

                @media (min-width: 768px) {
                    min-width: 125px;
                }

                @media (min-width: 1024px) {
                    min-width: 150px;
                }
            }
        }
    }

    .device-catalog() {
        a {
            color: black;
        }

        #panelDevices {
            min-height: 300px;
        }

        #panelDevices, #panelManageProjects, #panelSGVerticals {
            padding-top: 2rem;
            padding-left: 1.5rem;

            h2 {
                margin: 2rem 0rem 1rem 0rem;
            }

            a.nav-bubble-list-item {
                display: inline-block;
                text-align: center;
                text-decoration: none;

                .nav-text {
                    display: block;
                    color: black;
                    font-size: 1.2rem;
                    //font-weight: bold;
                    line-height: 0rem;
                }

                .nav-bubble {
                    display: inline-block;
                    height: 60px;
                    width: 60px;
                    border-width: 2px;
                    border-style: solid;
                    border-radius: 50%;
                    border-color: @telekomGrey5;
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: 75% auto;
                    margin-bottom: 10px;

                    @media (min-width: 768px) {
                        height: 80px;
                        width: 80px;
                        border-width: 3px;
                    }

                    @media (min-width: 1024px) {
                        height: 90px;
                        width: 90px;
                        border-width: 3px;
                    }

                    &.active {
                        display: none;
                        border-color: @telekomMagenta;
                    }
                }

                &.disabled {
                    .nav-bubble {
                        border-color: @navigationNodeInactive;
                    }

                    .nav-text {
                        color: @navigationNodeInactive;
                    }

                    &:hover, &:active, &:focus {
                        display: inline-block;

                        .nav-text {
                            color: @navigationNodeInactive;
                        }
                    }
                }

                &:hover, &:active, &:focus {
                    .nav-text {
                        color: @telekomMagenta;
                    }

                    .nav-bubble {
                        display: none;

                        &.active {
                            display: inline-block;
                        }
                    }
                }

                &.highlighted {
                    .nav-bubble {
                        box-shadow: @highlightBoxShadow;
                    }
                }
            }

            ul.nav {
                display: flex;
                width: 100%;
                justify-content: flex-start;

                li {
                    padding: 0rem 1rem;
                }
            }
        }

        ul.hardware-list {
            list-style-type: square;
        }

        iframe.datasheet {
            width: 100%;
            min-height: 600px;
            border-bottom-left-radius: .25rem;
            border-bottom-right-radius: .25rem;
            border-top-left-radius: 0;
            border-top-right-radius: 0;
            border: 1px solid #ced4da;
        }

        .doctype-group {
            width: 100%;
            display: flex;

            .btn {
                flex: 1;
                border-bottom-left-radius: 0;
                border-bottom-right-radius: 0;
            }
        }
    }
}

span.highlighted-text {
    text-shadow: @highlightTextShadow;
}

#Loader {
    .main() {
        .iot-loader, .iot-chart-loader {
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            top: calc(50% - 50px);
            display: flex;
            min-width: 200px;
            max-width: 250px;
            background-color: #4B4B4B;
            border: 1px solid #4B4B4B;
            border-radius: 15px;
            padding: 3px 3px 3px 15px;
            color: white;
            box-shadow: 0px 0px 8px 1px #4B4B4B;

            .iot-loader-spinner {
                border: 4px solid #f3f3f3; /* Light grey */
                border-top: 4px solid #E20074; /* Blue */
                border-radius: 50%;
                margin-right: 10px;
                width: 30px;
                height: 30px;
                -webkit-animation: spin 1s linear infinite; /* Safari */
                animation: spin 1s linear infinite;
            }

            @keyframes spin {
                0% {
                    transform: rotate(0deg);
                }

                100% {
                    transform: rotate(360deg);
                }
            }
        }
    }
}

#TIcon {
    .t-icon() {
        .t-icon {
            display: flex;

            span {
                margin: auto;

                &.solid {
                    font-family: 'TeleIcon Solid';
                }

                &.outline {
                    font-family: 'TeleIcon Outline';
                }
            }
        }
    }
}

#DevExtreme {
    .override() {
        .dx-dialog {
            &.dx-popup {
                .dx-popup-title.dx-toolbar .dx-toolbar-items-container {
                    height: 50px;
                }

                .dx-button-flat.dx-button-default {
                    color: @telekomMagenta;
                    border: 1px solid @telekomMagenta;
                    border-radius: 6px;

                    &:hover, &:active {
                        color: white;
                        background-color: @telekomMagenta;
                    }

                    &:focus {
                        outline: none;
                        box-shadow: -2px 1px 4px @telekomMagentaDark;
                    }

                    .dx-button-text {
                        text-transform: none;
                    }
                }
            }
        }
    }
}

#Accordion {
    .main() {
        .accordion-no-data {
            background-color: @telekomMagenta;
            color: white;
            min-height: 48px;
            padding: 5px 15px;
            font-family: 'TeleGrotesk Next';
            font-size: 1.3rem;
            font-weight: bold;
            align-items: center;
            display: flex;
            border-radius: 3px;
        }

        .accordion-title.level_0 {
            &.ui-state-active,
            &.ui-state-default,
            &.ui-state-hover,
            &.ui-state-focus {
                background-color: @telekomMagenta;
                color: white;
                border-color: @telekomMagenta;
                outline-color: black;
                padding-bottom: 5px;
                padding-top: 5px;
                // Technology card links
                a {
                    color: white;
                    text-decoration: underline;
                }
            }

            .ui-icon-triangle-1-e,
            .ui-icon-triangle-1-s {
                color: white;
            }

            min-height: 48px;
            align-items: center;
            display: flex;
        }

        .accordion-title.level_1, .accordion-title.level_2 {
            &.ui-state-active,
            &.ui-state-default,
            &.ui-state-hover,
            &.ui-state-focus {
                color: @telekomMagenta;
                background-color: #f6f6f6;
                border-color: #c5c5c5;
                outline-color: black;
                padding-bottom: 5px;
                padding-top: 5px;
            }

            .ui-icon-triangle-1-e,
            .ui-icon-triangle-1-s {
                color: @telekomMagenta;
            }

            .transparent-container {
                background: initial;
            }

            min-height: 48px;
            align-items: center;
            display: flex;
        }

        .accordion-title.level_1 {
            padding-left: 3em;

            &.ui-accordion-header-active {
                background: none;
                border: none;
            }
        }

        .accordion-title.level_2 {
            &.ui-accordion-header-active {
                background: none;
                border-bottom: none;
            }
        }

        .accordion-title-name {
            font-family: 'TeleGrotesk Next';
            font-size: 1.3rem;
            font-weight: bold;
        }

        .ui-accordion .ui-accordion-content {
            padding: 0;
        }

        .accordion-content {
            &.level_1 {
                padding-left: 3em;
                border: 0;
            }

            &.level_2 {
                padding-left: 0.75em;
            }
        }

        .ui-icon,
        .ui-widget-content .ui-icon,
        .ui-widget-header .ui-icon,
        .ui-state-hover .ui-icon,
        .ui-state-focus .ui-icon,
        .ui-button:hover .ui-icon,
        .ui-button:focus .ui-icon,
        .ui-state-active .ui-icon,
        .ui-button:active .ui-icon {
            background: none;
            text-indent: 0;
            margin-right: 10px;
        }

        .ui-icon-triangle-1-e:before {
            font-family: 'TeleIcon Solid';
            content: '$';
        }

        .ui-icon-triangle-1-s:before {
            font-family: 'TeleIcon Solid';
            content: '"';
        }

        .logo-container {
            position: relative;
            display: inline-block;
            width: 150px;
            background: white;
            text-align: center;
            height: 36px;
            margin-left: 5px;
            margin-right: 15px;
            padding-top: 3px;
        }

        .partner-logo {
            width: 100%;
        }

        .product-logo {
            width: 100%;
        }

        .accordion-title-name {
            min-width: 10rem;
        }

        .accordion-title-description {
            color: black;
        }

        .panel-accordion-product-layer {
            .accordion-title.level_1.ui-accordion-header-active {
                .logo-container {
                    background: initial;

                    img {
                        display: none;
                    }
                }
            }
        }

        #panelTechnologyCards {
            .logo-container {
                display: none;
            }

            .accordion-content {
                &.level_1 {
                    padding: 0 3em;
                }

                &.level_2 {
                    padding: 0 0.75em;
                }

                img {
                    max-width: 90%;
                    height: auto;
                    display: block;
                    margin-left: auto;
                    margin-right: auto;
                }

                text-align: justify;
            }
        }

        .accordion-content-panel {
            padding-left: 5rem;
            padding-top: 0.5rem;
            padding-bottom: 0.5rem;

            .row {
                margin-right: 0.5rem;
            }

            .tlogo {
                height: 1.5rem;
                padding-right: 1rem;
            }

            .document-container {
                padding-top: 0.5rem;
                padding-left: 0.6rem;
            }

            .document-name {
                padding-top: 0.45rem;
            }

            .document-icon:before {
                font-family: 'TeleIcon Outline';
                content: 'f';
                font-size: xx-large;
                padding-right: 0.5rem;
            }
        }

        .center-items {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;
        }

        .accordion-row {
            margin-right: 0.5rem;
            margin-bottom: 1rem;
            margin-top: 1rem;
        }

        .accordion-btn-group {
            flex-direction: column;
        }

        .accordion-button {
            width: 100%;
            padding: .375rem;
            margin-bottom: 0.5rem;
            white-space: pre-wrap;
            font-size: inherit;
            align-items: center;
            justify-content: center;
        }

        @media screen and (-webkit-min-device-pixel-ratio:0) {
            .accordion-btn-group {
                display: grid;
                grid-template-columns: 1fr;
                /*grid-auto-rows: 1fr;*/
            }

            .accordion-button {
                min-height: 0.5rem;
                display: flex;
            }
        }
        /*.accordion-button:nth-child(1) {
            -ms-grid-column: 1;
            -ms-grid-row: 1;
        }

        .accordion-button:nth-child(2) {
            -ms-grid-column: 1;
            -ms-grid-row: 2;
        }

        .accordion-button:nth-child(3) {
            -ms-grid-column: 1;
            -ms-grid-row: 3;
        }*/
    }
}
