﻿.company-section {
    background-color: rgba(191, 191, 191, 0.15);
    padding: 20px;
    margin-top: 30px;
    margin-bottom: 20px;
}
.dx-checkbox {
    padding-top: 13px;
}

div.dx-toast-success {
    // #1596
    // better contrast and bigger font for success toast (e.g. email sent successfully in 2FA process)
    background-color: darkgreen;
    font-size: 1.25em;
}