﻿@import "../_mixins.less";
#Accordion .main();

/* Wizard */

#wizard-container {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-content: center;
    align-items: center;
    margin: 10px 0;
}

.wizard-bubble {
    height: 90px;
    width: 90px;
    margin: 0;
    padding: 0;
    line-height: 0;
    border-radius: 50%;
    border: solid 5px rgb(75,75,75);
    background-color: white;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 65% auto;
    // Same subtle animation as bootstrap buttons
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.wizard-bubble-active {
    border-color: @telekomMagenta;
}

.wizard-bubble-active {
    cursor: pointer !important;

    &:hover {
        border-color: @telekomMagentaLight;
    }
}

.wizard-bubble-current {
    border-color: @telekomMagentaActive;
}

.wizard-bubble-small {
    margin: 0;
    padding: 0;
    line-height: 0;
    height: 20px;
    width: 20px;
    background-color: @telekomGrey5;
}

.wizard-bubble-small-active {
    background-color: @telekomMagenta;

    &:hover, &:active, &:focus {
        background-color: @telekomMagentaLight;
        border-color: @telekomMagentaLight;
    }
}

.wizard-bubble-small-current {
    background-color: white;
    border-color: @telekomMagentaActive;
    height: 30px;
    width: 30px;

    &:hover {
        //background-color: @telekomMagentaActive;
        background-color: white;
        border-color: @telekomMagentaActive;
    }
}

.connecting-line {
    flex: 1;
    display: flex;
    justify-content: space-around;
    align-content: center;
    align-items: center;
    height: 5px;
    background-color: @telekomGrey5;
}

.connecting-line-active {
    background-color: @telekomMagenta;
}

iframe.datasheet {
    width: 100%;
    min-height: 400px;
    border-bottom-left-radius: .25rem;
    border-bottom-right-radius: .25rem;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border: 1px solid #ced4da;
}

.doctype-group {
    width: 100%;
    display: flex;

    .btn {
        flex: 1;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }
}

.wizard-list {
    list-style: none;
    padding: 0;
}

/* Form validation */

.input-validation-error {
    border-color: red;
    color: red;
}

.field-validation-error {
    color: red;
}

.alert-danger {
    .field-validation-error {
        color: white;
    }
}

/* Radio button bubbles (e.g. for use case category) */

.radio-bubble-container {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.radio-bubble {
    .wizard-bubble;
    margin-right: 20px;
}

input[type=radio] {
    &:checked {
        & ~ .radio-bubble {
            border-color: @telekomMagenta;
        }

        & ~ .radio-bubble-label {
            font-weight: bold;
            color: @telekomMagenta;
        }
    }

    &:hover {
        & ~ .radio-bubble {
            border-color: @telekomMagentaLight;
        }

        & ~ .radio-bubble-label {
            color: @telekomMagentaLight;
        }
    }
}

#globeMap {
    .stroke {
        fill: none;
        stroke: #000;
        stroke-width: 1px;
    }

    .fill {
        fill: #f2f2f2;
    }

    .graticule {
        fill: none;
        stroke: #777;
        stroke-width: .5px;
        stroke-opacity: .5;
    }

    .noclick {
        pointer-events: none;
    }

    .land {
        fill: darkgrey;
    }

    .boundary {
        fill: none;
        stroke: #fff;
        stroke-width: 1px;
    }

    #globeRotation {
        position: absolute;
        right: 10px;
        padding: 10px;
        background: rgba(255, 255, 255, .5);

        input {
            width: 250px;
        }

        .angle-label {
            line-height: 1;
        }
    }

    .zoom-controls {
        padding-top: 2.5rem;
        position: absolute;

        button {
            display: block;
            min-width: 35px;
            width: 35px;
            height: 35px;
            padding: 0;

            &:focus {
                box-shadow: 0 0 0 0 rgba(255, 141, 200, 0.5) !important;
            }

            span {
                font-weight: bold;
                font-size: 24px;
            }
        }

        #zoomInButton {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
            border-bottom: none;
        }

        #zoomOutButton {
            border-top-left-radius: 0;
            border-top-right-radius: 0
        }
    }

    input[type=range] {
        -webkit-appearance: none;
        width: 100%;
        background: transparent;
        padding: 0px;

        &:focus {
            outline: none;
        }
        /*Styling the Track*/
        &::-webkit-slider-runnable-track {
            width: 100%;
            height: 0.8em;
            cursor: default;
            background: #fff;
            border-radius: 3px;
            border: 1px solid #c5c5c5;
        }

        &:focus::-webkit-slider-runnable-track {
            background: #fff;
        }

        &::-moz-range-track {
            width: 100%;
            height: 0.8em;
            cursor: default;
            background: #fff;
            border-radius: 3px;
            border: 1px solid #c5c5c5;
        }

        &:focus::-moz-range-track {
            background: #fff;
        }

        &::-ms-track {
            width: 100%;
            height: 0.8em;
            cursor: default;
            background: transparent;
            border-color: transparent;
            border-width: 8px 0;
            color: transparent;
        }

        &::-ms-fill-lower {
            background: #fff;
            border: 1px solid #c5c5c5;
            border-radius: 3px;
        }

        &:focus::-ms-fill-lower {
            background: #fff;
        }

        &::-ms-fill-upper {
            background: #fff;
            border: 1px solid #c5c5c5;
            border-radius: 3px;
        }

        &:focus::-ms-fill-upper {
            background: #fff;
        }
        /*Styling the Thumb*/
        /* Special styling for WebKit/Blink */
        &::-webkit-slider-thumb {
            -webkit-appearance: none;
            border: 1px solid #c5c5c5;
            height: 1.2em;
            width: 1.2em;
            border-radius: 3px;
            background: @telekomMagenta;
            cursor: default;
            margin-top: -0.25em;
        }

        &:focus::-webkit-slider-thumb {
            outline: none;
            border: none;
            box-shadow: 0 0 0 0.2rem fade(@telekomMagentaLight, 50%);
            background-color: @telekomMagentaActive !important;
        }
        /* All the same stuff for Firefox */
        &::-moz-range-thumb {
            border: 1px solid #c5c5c5;
            height: 1.2em;
            width: 1.2em;
            border-radius: 3px;
            background: @telekomMagenta;
            cursor: default;
        }

        &:focus::-moz-range-thumb {
            outline: none;
            border: none;
            box-shadow: 0 0 0 0.2rem fade(@telekomMagentaLight, 50%);
            background-color: @telekomMagentaActive !important;
        }
        /* All the same stuff for IE */
        &::-ms-thumb {
            border: 1px solid #c5c5c5;
            height: 1.2em;
            width: 1.2em;
            border-radius: 3px;
            background: @telekomMagenta;
            cursor: default;
            margin: 3px;
        }

        &:focus::-ms-thumb {
            outline: none;
            border: none;
            box-shadow: 0 0 0 0.2rem fade(@telekomMagentaLight, 50%);
            background-color: @telekomMagentaActive !important;
        }

        &::-ms-tooltip {
            display: none;
        }
    }
}

div.map-tooltip {
    background: rgba(0,0,0,.8);
    color: #eee;
    font: normal 12px 'Lucida Grande',Arial,Helvetica,sans-serif;
    padding: .4em 1em;
    text-align: center;
    text-shadow: 0 1px 0 #000;
    white-space: nowrap;
    border-radius: .4em;
    position: absolute;
    z-index: 999;
}

.tooltip-hidden {
    display: none;
}
/* CSS Map adjustments*/
.cssmap-container {
    margin-top: 1rem;
}

.natco-img-flag {
    width: 40px;
    margin-right: 15px;
    box-shadow: 0px 0px 3px 1px #7f7f7f
}

.select2 {
    .selection {
        .natco-option {
            padding-left: 10px;
        }

        .natco-img-flag {
            width: 30px;           
        }

        .select2-selection__choice {
            padding-top: 5px;
            padding-bottom: 5px;
            margin-right: 20px;
            margin-left: 5px;
            margin-top: 2px;
            margin-bottom: 5px;
        }
    }
}
#selectFlagText, #oneCountryFlagText, #marketsPercentageLabel {
    display: flex;
    min-height: 60px;
    align-items: center;
}

#selectTariffCountryFlags {
    display: flex;
    min-height: 60px;
    align-items: center;

    .natco-img-flag-container {
        width: 70px;
        height: 50px;
        padding: 5px;
        margin-right: 0px;

        .flag-wrapper {
            padding: 5px;

            &.available-tariff-network {
                outline: 2px solid lightgray;
            }

            &.selected-tariff-network {
                outline: 2px solid @telekomMagenta;
            }

            img {
                width: 50px;
                height: 30px;
                box-shadow: 0px 0px 3px 1px #7f7f7f;
            }
        }
    }
}

#marketsPercentage {
    display: flex;
    min-height: 60px;
    align-items: center;

    .market-percentage {
        margin-right: 5px;
        margin-left: 5px;
        text-align: center;

        input {
            width: 60px;
            text-align: center;
        }
    }

    input[type=number]::-webkit-inner-spin-button,
    input[type=number]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
}

.tariff-label {
    margin-left: 0.5rem;
}

.summary-container {
    .natco-img-flag {
        width: 50px;
        margin: 5px;
    }

    h1 {
        font-size: 1.7rem;
        margin: 0;
    }

    h2 {
        font-size: 1.2rem;
    }

    h3 {
        font-size: 1.2rem;
    }

    h4 {
        font-size: 1.1rem;
    }

    hr {
        margin-top: 0.25rem;
        margin-bottom: 0.5rem;
    }

    .separator {
        display: block;
        width: 100%;
        height: 0.5rem;
    }

    .prop-value {
        font-size: 1.1rem;
        font-weight: bold;
    }

    .prop-label {
        display: block;
        font-size: 0.9rem;
        padding: 2px 8px;
    }

    .radio-bubble {
        .wizard-bubble;
        height: 40px;
        width: 40px;
        background-size: 100% auto;
        border-radius: 0;
        border: none;
        margin-right: 15px;

        &:hover {
            cursor: default;
        }
    }

    .summary-nav {
        font-size: 1.2rem;
        font-weight: normal;
    }

    .back-to-top {
        float: right;
        font-weight: normal;
    }

    #TableofContents {
        margin-top: 2rem;

        h1 {
            // Remove duplicate line in demo project summary
            border-bottom: none;
        }

        ul {
            margin: 1rem;
        }

        li {
            font-size: 1.2rem;
            font-weight: bold;
        }
    }

    .btn {
        min-width: 200px;
        margin-bottom: 0.3rem;
    }

    .chapter-container {
        margin-top: 2rem;
    }

    .iot-chart-loader {
        position: relative;
        display: flex;
    }

    .waiting-indicator-text {
        margin-top: 170px;
        font-weight: bold;
    }

    .chart-container {
        min-height: 400px;
        height: auto !important;
    }

    .chart-component {
        margin: 1.5rem 0 1rem 0;

        h3, h4 {
            text-align: center;
        }

        .diagramLimitedDisclaimer {
            padding: 1rem 1rem 0 1rem;
            font-weight: bold;
        }
    }

    .chart-info-container {
        padding: 0 1rem;
        margin: 1.5rem 0;
    }

    .svg-container {
        overflow: visible;
        padding-bottom: 53%;
    }

    .optimization-incentives-container {
        padding: 1rem 1rem 0 1rem;
        font-weight: bold;
        border: solid 1px #ededed;
        margin: 0.5rem 0 0.5rem 0;

        p {
            font-size: 1.2rem;
        }

        .optimization-potential-text {
            color: #007bff;
            text-shadow: #5afbda 0px 0px 1em, #2bdbf7 0px 0px 0.5em;
        }

        .optimized-text {
            color: #1fa12d;
            text-shadow: 0 0 1em #c0ff02, 0 0 0.5em #6be022;
        }
    }
}
.summary-container[data-multimode='True'] {
    .svg-container {
        padding-bottom: 55%;
    }
}

select#EdrxCycle option:disabled {
    display: none;
}

.separator {
    display: block;
    width: 100%;
    height: 1.5rem;
}

.application-logo {
    width: 40px;
    margin-right: 15px;
}

/* Payload & Protocols */
.payload-draggable {
    width: 120px;
    min-height: 120px;
    color: white;
    font-weight: bold;
    margin-bottom: 20px;
    text-align: center;
    z-index: 20;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.5rem;
    // Same border as other modals
    border: 1px solid rgba(0,0,0,.2);
    border-radius: .3rem;
    line-height: 1.1;

    &:hover {
        cursor: pointer;
    }

    &:focus {
        box-shadow: 0 0 0 0.2rem rgba(255, 141, 200, 0.5) !important;
    }
}

.uplink-draggable {
    background: rgb(0, 112, 192);
}

.downlink-draggable {
    background: rgb(79, 163, 239);
}

.protocol-container {
    label {
        margin-bottom: 20px;

        &.protocol-category {
            font-weight: bold;
        }
    }
}

.ui-slider {
    margin-top: 14px; /* To match the height of the input field next to it */
    .ui-slider-handle {
        background-color: @telekomMagenta !important;

        &.ui-state-focus, &.ui-state-active {
            outline: none;
            border: none;
            box-shadow: 0 0 0 0.2rem fade(@telekomMagentaLight, 50%);
            background-color: @telekomMagentaActive !important;
        }
    }

    .ui-slider-range {
        background: @telekomMagentaLight;
    }

    #custom-handle {
        width: 3em;
        height: 1.6em;
        top: 50%;
        margin-top: -.8em;
        text-align: center;
        line-height: 1.6em;
    }
}

.ui-slider.ui-slider-disabled {
    .ui-slider-handle {
        background-color: @telekomGrey5 !important;
    }

    .ui-slider-range {
        background: @telekomGrey6;
    }

    input[type='text'] {
        color: black;
    }
}

#payload-settings {
    display: none;
    position: absolute;
    z-index: 100;
    background: rgb(226,0,116);
    color: white;
    padding: 10px;
    // Same border as other modals
    border: 1px solid rgba(0,0,0,.2);
    border-radius: .3rem;

    button {
        margin-top: 10px;
    }
}
// Small modal with a layout similar to technology cards
.payload-modal {
    position: absolute;
    color: white;
    display: none;
    padding: 20px;
    border: 1px solid rgba(0,0,0,.2);
    border-radius: .3rem;
    z-index: 200;

    label {
        margin-bottom: 0.33rem;

        &.payload-modal-title {
            font-size: 1.2rem;
        }
    }
}

#payload-modal {
    background: @telekomMagenta;
    
    button {
        margin-top: 1rem;
        flex: 1;
    }

    a, a:focus, a:hover, a:active, a:link {
        color: white;
        text-decoration: underline;
    }

    #payload-modal-header {
        display: flex;
        justify-content: space-between;
        align-content: center;
    }
}

#onem2m-request-modal, #onem2m-response-modal, #msg-protocol-modal, #http-request-modal, #http-response-modal {
    background: @telekomGrey1;
    padding: 10px;

    label {
        color: white;
        font-weight: bold;
    }
}

.selling-points {
    font-size: small;

    hr {
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
    }

    p {
        margin-bottom: 0px !important;
    }
}

.data-events-highlight {
    font-size: 1.2rem;
    font-weight: bold;
}

#panelAccordionSelectBattery,
#panelConfigureCustomBattery,
#modalBatterySelectionGuide,
#panelAccordionBatteries {
    .input-group, .input-group-append {
        height: 2rem;
    }

    .form-group {
        margin-bottom: 0.2rem;
    }

    .unit-input-group {
        .form-control {
            height: 100%;
            padding-right: 0;
        }
    }

    .battery-warning {
        font-family: 'TeleGrotesk Next';
        font-size: 1rem;
        color: @telekomMagenta;
        text-align: center;
        padding-top: 0.5rem;
    }
}

#panelAccordionSelectBattery {
    .text-danger {
        padding-left: 15px;
    }
}

#panelConfigureCustomBattery {
    padding-left: 3rem;
}

.disclaimer-text {
    font-size: medium;
    color: #D67600;
}

.protocol-svg {
    padding: 10px;
    min-height: 0 !important;
}

.traffic-warning {
    // display:inline-block in JavaScript!
    display: none;
    left: 50%;
    transform: translateX(-50%);
    margin-bottom: 1rem;
}

.header-size-container {
    margin-bottom: 0;
}

#MicroOptimizationHintContainer {
    background: dimgrey;
    color: white;
    width: 220px;
    padding: 1rem;
    margin: 0.5rem 0;
    display: none;

    a {
        color: white;
        text-decoration: underline;
    }
}

.selection-guide-hint-container {
    background: dimgrey;
    color: white;
    width: auto;
    padding: 0.75rem 1.5rem;
    margin: 0.5rem 0;
    display: inline-block;

    a, a:focus, a:hover, a:active, a:link {
        color: white;
        text-decoration: underline;
    }
}

#MicroOptimizationHintContainer {
    position: absolute;
    z-index: 1000;
}

.criterion-checkbox {
    display: block;
    padding: 7px;
    margin-bottom: 10px;
    border: 1px solid #e3e3e3;
    border-radius: 4px;
    box-shadow: 2px 2px 1px 0px rgba(0, 0, 0, 0.05);
    -webkit-box-shadow: 2px 2px 1px 0px rgba(0, 0, 0, 0.05);

    input[type=checkbox] {
        margin-right: 20px;
    }
}

.handle-value {
    margin-top: 25px;
    width: 80px;
    margin-left: -30px;
    background: transparent;

    input {
        border: 0;
        color: #E20074;
        font-weight: bold;
        width: 105%;
        text-align: center;
        background: transparent;
    }
}

.slider-value-min {
    float: left;
    padding-left: 0.5rem;
}

.slider-value-max {
    float: right;
    padding-right: 0.5rem;
}

#modalBatterySelectionGuide {
    .ui-slider {
        padding-left: 0;
        margin-bottom: 3rem;
    }    

    .moc-checkbox-label {
        font-weight: bold;
        font-size: 1.15rem;
    }

    fieldset {
        padding: 0px 10px;
        margin-bottom: 20px;        
        border: 1px solid #e3e3e3;
        border-radius: 4px;
        -webkit-box-shadow: 2px 2px 0px 1px rgba(0, 0, 0, 0.05);
        box-shadow: 2px 2px 0px 1px rgba(0, 0, 0, 0.05);
    }

    legend {
        display: block;
        font-size: 14px;
        padding: 2px 7px 2px 5px;
        margin-bottom: 20px;
        line-height: inherit;
        color: #333;
        background: #fff;
        border: 1px solid #e3e3e3;
        border-radius: 4px;
        box-shadow: 2px 2px 1px 0px rgba(0, 0, 0, 0.05);
        -webkit-box-shadow: 2px 2px 1px 0px rgba(0, 0, 0, 0.05);
    }
}

#panelRecommendedBatteries {
    .battery-name {
        font-size: 1.3rem;
        font-weight: bold;
    }

    .battery-description {
        font-size: 1rem;
        margin-bottom: 0;
        line-height: 1rem;
    }

    .man-logo {
        max-height: 50px;
        max-width: 90px;
        margin-top: 10px;
    }

    .configuration-list {
        margin-top: 0.5rem;
        margin-bottom: 0;
    }
}

#modalPSMTimerConfiguration {
    .modal-content {
        min-width: 700px;
    }

    .modal-html-content {
        padding: 0;
    }

    #svg-container {
        min-height: 250px;

        .svg-container {
            padding-bottom: 60%;
            position: initial;
        }
    }
}

.atcommandname {
    color: @telekomMagenta;
}


.dx-row {
    td {
        &.at-command-field {
            line-height: 1.4rem;
            word-wrap: break-word;
        }
    }
}

#svg-container-psm {
    .svg-container {
        width: 100%;
        height: 370px;
    }
}

#svg-container-edrx, #svg-container-ptw {
    .svg-container {
        width: 100%;
        height: 300px;
    }
}

#containerRecommendedBatteries {
    background-color: white;
    color: black;
    padding: 1rem;
    text-align: justify;
    min-height: 200px;
}

.chart-error-message, .moc-error-message {
    display: none;
    top: 10px; // margin-top doesn't work for some reason
    margin: 0 10px;
    text-align: center;
}

.diagramLimitedDisclaimer {
    color: @telekomMagenta;
}

#data-events-radio-container {
    label {
        margin-top: 10px;
    }
}

#data-events-config-area {
    .data-events-config {
        display: none;

        .col {
            margin:0 20px 20px 20px;
        }
    }
}

/* OneM2M Wizard Step */

/*.onem2m-request{
    margin-bottom: 20px;
}*/

#onem2m-error-message {
    display:none;
}

#ldo-feature-container {
    border:solid 1px @telekomGrey4;
    padding:15px;
    margin-bottom:1rem;
}

#panelAccordionSelectHardwareType,
#panelAccordionSelectModule,
#panelAccordionSelectChipset {
    .performance-button {
        display: none;
    }
}

h2.summary-headline {
    color: black;
    font-weight: bold;
}

select.single-value {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    pointer-events: none;
}

// Transport & Security Protocol:
#TcpKeepaliveContainer {
    border: solid 1px #ededed;
    padding: 15px;
}

// Communication Activity Screen:
#messageListToolbar {
    .dx-toolbar-label {
        max-width: none;

        .dx-toolbar-item-content {
            font-weight: bold;
        }
    }
}

// Wizard Step Antenna Placement

#placements .dx-tile-content .image {
    height: 100%;
    width: 100%;
    background-position: center;
    background-size: cover;
    padding: 5px;
    display: block;
}

#placements .dx-tile {
    border: 3px solid white transparent;
    border-radius: 10px;
}

.dx-tile.dx-state-focused {
    border: 3px solid;
    border-color: @telekomMagenta;
}

#placements .dx-tile-content {
    position: relative;
}

.centered-warning {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%)
}

/* Radio Access Technology Step */
.network-list {
    ul {
        padding-left: 18px;
    }
}


/*result page*/
@media screen and (max-width: 991px) {
    html {
        body {
            main div.summary-container {
                div.chapter-container {
                    span.prop-label {
                        padding: 0;
                    }

                    span.prop-value {
                        padding-left: 15px;
                    }
                }
            }
        }
    }
}

/*result page*/
@media screen and (max-width: 991px) {
    html {
        body {
            main div.summary-container {
                div.chart-container {
                    min-height: 0;
                }
            }
        }
    }
}