.contact-validaiton-summary {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 0 1rem 0;

    ul {
        list-style: none;
        margin: 0;
        padding: unset;
        li { 
            list-style: none;
            &:not(:first-child) {
                border-top: black solid 1px;
                padding: 0.25rem;
                margin-top: 0.25rem;
            }
        }
    }
}
