﻿@import "../_definitions.less";

#masterNavigation {
    &.login-navigation {
        a {
            &:not(.magenta).disabled {
                circle, line {
                    stroke: @telekomGrey5;
                }
            }
        }
    }
}

// Video
.iso-video-container {
    position: absolute;
    top: 0;
    right: 0;
    overflow: hidden;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,.3);
    z-index: -1000;
}

.iso-video {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    z-index: -1000;
    overflow: hidden;
}

// Login form
.login-form-container {
    display: flex;
    justify-content: center;

    #loginForm {
        display: inline-block;
        vertical-align: top;
    }
}