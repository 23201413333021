﻿@import "_mixins";

//#MasterPage .master-page();

/* Common elements */

// due to BITV (accessibility) all focusable element should have a "good visible and perceptible" focus border
//TODO: should be commented out later
/**:focus {
    outline: 1px solid #00739F;
}*/

html {
    position: relative;
    min-height: 100%;
    font-size: 1.2rem;
    // Always show vertical scroll bar
    //overflow-y: scroll;
    body {
        /*overflow: auto;*/
        font-family: 'TeleGrotesk Next',sans-serif;
        /* Margin bottom by footer height */
        margin-bottom: 90px;
        min-height: 300px;
        overflow-y: scroll;

        // fix popup overlay issue with dark background
        &.modal-open {
            section.header-container,
            header.second-header {
                position: initial;
            }
        }

        h1 {
            font-weight: bold;
        }

        a, a:hover {
            color: @telekomLink;

            &.link-underlined {
                text-decoration: underline;
            }
        }

        label {
            margin-bottom: 0;
            font-size: 1rem;
        }

        .link-underlined {
            a {
                text-decoration: underline;
            }
        }
    }

    body:not(.modal-open) {
        padding-right: 0px !important;
    }
}

ul {
    list-style-type: square;
}

div.hide-element {
    display: none;
}

/* Navbar */

nav.bg-dark {
    background-color: @telekomMagenta !important;
    height: 70px !important;
    color: white;
}

img.navigation-logo {
    height: 55px;
}

img.navigation-logo-right {
    max-height: 55px;
}

.cookie-message-bar {
    width: 100%;
    z-index: 502;
    background: #373737;
    color: #fff;
    padding: .75rem 1.25rem;
    font-size: 15px;
    overflow: hidden;
    -webkit-tap-highlight-color: transparent;

    a, a:hover {
        color: white;
        text-decoration: underline;
    }
}

/* Footer */
footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    /* Set the fixed height of the footer here */
    height: 70px;
    line-height: 70px; /* Vertically center the text there */
    background-color: #f5f5f5;
    color: @telekomGrey5;
    z-index: 100; /* Z-index bigger than active countries on world map */
    font-size: 1rem;

    a {
        color: #6c757d !important;
        margin-left: 20px;
    }
}

/* Breadcrumb */
.breadcrumb {
    background: none;
    padding: 0.5rem 0;
    margin-bottom: 0;
    min-height: 40px;
}

#breadcrumb-container {
    background-color: #FFFFFF;
    margin-bottom: 20px;
}

.breadcrumb-item {
    // The separator between breadcrumbs (by default, a forward-slash: "/")
    + .breadcrumb-item {

        &::before {
            font-family: 'TeleGrotesk Next';
            content: '\203A';
        }
    }
}

.nav-item {
    #navbar-label {
        color: @telekomMagenta;
    }

    a {
        color: @telekomGrey5;

        &:not(.dropdown-item):hover {
            text-decoration: underline;
            color: @telekomMagenta;
        }
    }
}

// Same color for all breadcrumbs
.breadcrumb-item, .breadcrumb-item.active {
    color: @telekomGrey5;

    a {
        color: @telekomGrey5;
    }

    a:hover {
        color: @telekomMagenta;
    }
}

#main-navbar {
    #main-navlist {
        .dropdown-toggle::after {
            // Dropdown arrows (source: https://www.w3schools.com/howto/howto_css_arrows.asp)
            border: solid black;
            border-width: 0 1px 1px 0;
            display: inline-block;
            padding: 3px;
            transform: rotate(45deg);
            -webkit-transform: rotate(45deg);
        }

        li.nav-item:first-child .nav-link {
            padding-left: 0;
            min-width: 50px;
        }

        #navbar-label::after {
            content: '';
            margin-left: 0.5rem;
            margin-bottom: 0.1rem;
            border: solid @telekomMagenta;
            border-width: 0 1px 1px 0;
            display: inline-block;
            padding: 3px;
            transform: rotate(-45deg);
            -webkit-transform: rotate(-45deg);
        }

        .dropdown-menu {
            margin-top: 0;
        }
    }
}

/* Buttons */
.btn {
    min-width: 150px;
}

.btn-outline-primary {
    color: @telekomMagenta;
    border-color: @telekomMagenta;

    &:hover {
        background: @telekomMagenta;
        border-color: @telekomMagenta;
        box-shadow: 0 0 5px rgba(0,0,0,0.3);
        color: white;
    }

    &:active, &:focus {
        border-color: @telekomMagenta !important;
        box-shadow: 0 0 0 0.2rem fade(@telekomMagentaLight, 50%) !important;
        background: @telekomMagenta !important;
        color: white !important;
    }

    &.disabled, &:disabled {
        opacity: .65;
        color: graytext;
        background-color: buttonface;
        border: 1px solid transparent;
    }
}

.dropdown.show > .btn-outline-primary.dropdown-toggle {
    color: white;
    background: @telekomMagenta;
    border-color: @telekomMagenta;
    box-shadow: 0 0 0 0.2rem fade(@telekomMagentaLight, 50%) !important;
}

.btn-primary {
    color: white;
    border-color: @telekomMagenta;
    background: @telekomMagenta;

    &:hover {
        color: @telekomMagenta;
        border-color: @telekomMagenta;
        background: white;
    }

    &:active, &:focus, &:not(:disabled):not(.disabled):active {
        border-color: @telekomMagenta !important;
        background: white;
        box-shadow: 0 0 0 0.2rem fade(@telekomMagenta, 50%) !important;
        color: @telekomMagenta;
    }
}

.btn-default {
    color: black;
    border-color: @telekomGrey1;
    background-color: white;
    box-shadow: 0px 0px 1px 1px @telekomGrey6;

    &:hover, &:active, &:focus {
        background-color: @telekomGrey3;
        color: white;
    }

    &.disabled, &:disabled {
        opacity: .65;
        color: graytext;
        background-color: buttonface;
        border: 1px solid transparent;
        box-shadow: none;
    }
}
/* Inputs */
.form-control {
    /*border-color: @telekomGrey5;*/
    color: @telekomGrey5;

    &:not([disabled]):hover {
        border-color: @telekomMagentaLight;
        color: @telekomMagentaLight;
        background: white;
    }

    &:not([disabled]):active, &:focus {
        border-color: @telekomMagenta;
        color: @telekomMagenta;
        box-shadow: 0 0 0 0.2rem fade(@telekomMagentaLight, 50%);
    }

    option:disabled {
        color: @telekomGrey1;
    }
}

label:hover {
    cursor: pointer;
}
/* Checkboxen */
input[type='checkbox']:not(.checkbox-switch) {
    margin-right: 12px;

    &:before {
        margin-top: -5px;
        background: white;
        border: solid 1px @telekomGrey5;
        display: block;
        height: 24px;
        width: 24px;
        border-radius: 2px;
        content: '';
        font-size: 1em;
        text-align: center;
    }

    &:checked:before {
        background: @telekomMagenta;
        content: '✓';
        color: white;
    }

    &:enabled:hover, &:enabled:focus, &:enabled:active {
        border: none;
        outline: none;

        &:before {
            border-color: @telekomMagenta;
            outline-color: @telekomMagenta;
            cursor: pointer;
            box-shadow: 0 0 5px rgba(0,0,0,0.3);
        }
    }

    &:enabled:hover:before {
        background: @telekomMagentaLight;
    }

    &:disabled:before {
        background: @telekomGrey1;
    }
}
/* Switch (https://codepen.io/aorcsik/pen/OPMyQp) */
// TODO: Make switch appear at the same height as labels
input[type="checkbox"].checkbox-switch {
    position: absolute;
    margin: 8px 0 0 16px;
    display: none;

    & ~ label {
        position: relative;
        padding: 0px 0 0 48px;
        line-height: 2.0em;

        &:before {
            content: "";
            position: absolute;
            display: block;
            left: 0;
            top: 0;
            width: 40px; /* x*5 */
            height: 24px; /* x*3 */
            border-radius: 16px; /* x*2 */
            background: @telekomMagentaLight;
            border: 1px solid #d9d9d9;
            -webkit-transition: all 0.2s;
            transition: all 0.2s;
            top: 8px;
        }

        &:after {
            content: "";
            position: absolute;
            display: block;
            left: 0px;
            top: 0px;
            width: 24px; /* x*3 */
            height: 24px; /* x*3 */
            border-radius: 16px; /* x*2 */
            background: #fff;
            border: 1px solid #d9d9d9;
            -webkit-transition: all 0.2s;
            transition: all 0.2s;
            top: 8px;
        }

        &:hover:after {
            box-shadow: 0 0 5px rgba(0,0,0,0.3);
        }
    }

    &:checked ~ label {
        &:after {
            margin-left: 16px;
        }

        &:before {
            background: @telekomMagenta;
        }
    }

    &:disabled ~ label {
        &:before {
            background: @telekomGrey1;
            cursor: default;
        }

        &:after {
            cursor: default;
        }

        &:hover:after {
            box-shadow: none;
        }
    }
}

// Needed so that columns containing a switch-style checkbox are aligned properly
.switch-col {
    margin-top: -5px;
}

// Used for a switch-style checkbox with a larger gap (37px each) in between the labels and the switch
input[type="checkbox"].checkbox-switch.switch-gap {
    margin-left: 45px; // 8 + 37
    & ~ label {
        padding-left: 122px; // 48 + 2 * 37
        &::before, &::after {
            left: 37px;
        }
    }
}

.form-check-label {
    text-align: left;
    margin-left: 20px;
}

/* Borders */
.border-grey {
    border-color: @telekomGrey6 !important;
}

.container-border {
    border: solid 1px @telekomGrey4;
    padding: 0.75rem;
}

/* DropDowns */
.dropdown-item {
    &.active,
    &:active,
    &:focus {
        background-color: @telekomMagenta;
        color: white;
    }
}

h1, h2, h3 {
    font-weight: bold;
}

h1 {
    // Similar look to <hr>:
    border-bottom: solid 1px rgba(0, 0, 0, 0.1);
    padding-bottom: 12px;
}

// No bottom border in technology cards
.modal, .dx-htmleditor {
    h1 {
        border-bottom: none;
        padding-bottom: 0;
    }
}

h4.modal-title {
    font-weight: bold;
}

.main-header {
    h1, h2, h3 {
        //color: @telekomMagenta;
        text-transform: uppercase;
    }

    h1 {
        font-size: 2rem;
    }

    h2 {
        font-size: 1.5rem;
    }
}

h2 {
    font-size: 1.7rem;
}

h3 {
    font-size: 1.4rem;
}

/* Unit Input Group */
.unit-input-group {
    input, select {
        border-right: none;
        padding-right: 0.35rem; // Only half of regular padding because we've got the label padding too
        &.input-validation-error + div > span.input-group-text {
            border-color: red;
            color: red;
        }

        &:hover + div > span.input-group-text {
            border-color: @telekomMagentaLight;
        }
    }

    input[readonly], input[disabled], select[readonly], select[disabled] {
        & + .input-group-append > span.input-group-text {
            background-color: #e9ecef;
        }

        &:hover + .input-group-append > span.input-group-text {
            color: unset;
            border-color: #ced4da;
        }
    }

    .input-group-append {
        .input-group-text {
            border-left: none;
            background-color: white;
            padding-left: 0.35rem; // Only half of regular padding because we've got the input padding too
        }
    }
}

.hidden {
    position: absolute;
    left: -9999px;
}

.uc-name {
    font-weight: bold;
}

table.chart-info {
    width: 100%;
    min-width: 650px;

    thead tr {
        border-bottom: 1px solid @telekomGrey5;
    }

    tr {
        &:nth-child(2),
        &:nth-child(3),
        &:nth-child(4),
        &:nth-child(5),
        &:nth-child(6),
        &:nth-child(7) {
            border-top: 1px solid @telekomGrey5;
        }
    }

    th {
        font-weight: bold;
        text-align: center;
    }

    th, td {
        padding: 2px 5px;

        &:nth-child(1) {
            width: 20%;
            min-width: 150px;
            font-weight: bold;
            border-right: 1px solid @telekomGrey5;
        }

        &:nth-child(2) {
            border-right: 1px solid @telekomGrey5;
        }

        &:nth-child(2), &:nth-child(3) {
            width: 40%;
            min-width: 250px;
        }
    }

    td {
        .chart-info-cell {
            display: flex;
            justify-content: space-between;
            color: @telekomGrey3;
            font-size: 0.9rem;
            padding: 0px 10px;
        }
    }
}

#svg-container {
    min-height: 500px;
    height: auto !important;
}

.svg-container {
    overflow: visible;

    svg {
        overflow: visible;

        .currentUseCaseText {
            font-size: 0.8rem;
        }
    }

    .sublabel, #legend {
        font-size: 0.8rem;
    }
}

#TIcon .t-icon();
/*Modal*/
.modal-content {
    min-height: 200px;
    min-width: 550px;
    width: fit-content;
    background-color: @telekomMagenta;
    color: white;
    overflow: hidden;
}

.modal-body {
    overflow-y: auto;
}

.modal-open .modal {
    overflow-x: hidden;
    overflow-y: hidden;
}

.wiki-link {
    text-decoration: underline;
}

/*Modal window for technology cards, benefit of telekom, micro-optimization cards*/
.modal-card {
    .modal-content {
        .modal-header {
            border: none;
            padding: 0.5rem 1rem 0rem 1rem;

            button {
                padding: 1rem 1rem 1rem 0.5rem;
                margin: -1rem -1rem -1rem auto;

                &.close {
                    color: white;
                    opacity: 1;
                }
            }

            a.wiki-link {
                color: white;
            }
        }

        .modal-body {
            padding: 0 1rem;
            overflow-x: hidden;
        }

        .modal-description {
            margin: 1rem;
            font-size: 0.9rem;
            font-weight: bold;
            text-align: justify;

            a.wiki-link {
                color: white;
            }
        }

        min-width: 600px;
        padding-bottom: 1rem;
    }

    .modal-html-content {
        background-color: white;
        color: black;
        padding: 1rem;
        text-align: justify;
        min-height: 200px;
    }
}

#modalTechnologyCard, #modalBenefitOfTelekom {
    .modal-body {
        img {
            max-width: 100%;
            height: auto;
            display: block;
            margin-left: auto;
            margin-right: auto;
        }
    }
}

#customer-profile-card-modal {
    .modal-dialog {
        .modal-content {
            .modal-description {
                font-size: 1rem;
            }
        }
    }
}

#ModalRateProduct {
    .modal-content {
        .modal-header {
            border: none;
            padding: 0.5rem 1rem 0rem 1rem;
        }

        .modal-body {
            padding: 0 1rem;
        }

        min-height: 160px;
        min-width: 400px;
        background-color: white;
        color: black;
        padding-bottom: 1rem;
        left: 90%;
    }
}

#DevExtreme .override();

/**Styles overrides for danger texts and message boxes*/
.list-group-item-danger {
    color: white;
    background-color: @telekomRed;
}

.text-danger {
    color: @telekomRed;
}

.alert-danger {
    color: white;
    background-color: @telekomRed;
    border-color: @telekomRed;
}

.dx-label-v-align .dx-field-item-content .dx-invalid-message > .dx-overlay-content {
    -webkit-transform: translate(0px, 28px) !important;
    transform: translate(0px, 28px) !important;
}

.dx-invalid-message > .dx-overlay-content {
    font-size: 16px;
}

.dx-invalid.dx-texteditor .dx-texteditor-buttons-container {
    display: none;
}

/**Styles overrides for select2 in market selection view*/
#selectMarket {
    .select2-container--bootstrap4 .select2-selection__clear {
        position: absolute;
        bottom: 10px;
        right: 10px;
        font-size: 1.5rem;
    }

    .select2 .selection .select2-selection__choice {
        min-width: 464px;
    }

    .select2-container--bootstrap4 .select2-selection--multiple .select2-selection__rendered {
        display: flex;
        flex-wrap: wrap;
    }

    .select2 .selection .select2-selection {
        padding-top: 5px;
        line-height: 31px;
    }
}

#welcomeSelectMarket {
    .select2 .selection .select2-selection {
        padding-top: 5px;
        line-height: 31px;
    }
}

.select2-container--bootstrap4 {
    .select2-selection--single {
        padding-left: .75rem !important;
        color: @telekomGrey5;

        &:hover, &:active, &:focus {
            border-color: @telekomMagentaLight;
            color: @telekomMagentaLight;
            background: white;
        }
    }

    .select2-selection {
        color: @telekomGrey5;
    }

    .select2-dropdown {
        border-color: @telekomMagentaLight;
    }

    width: auto !important;
}

.select2-container--bootstrap4.select2-container--focus:not(.select2-container--open) {
    .select2-selection {
        border-color: @telekomMagentaLight;

        &:active, &:focus {
            box-shadow: 0 0 0 0.2rem fade(@telekomMagentaLight, 50%);
        }
    }
}

.select2-container--bootstrap4.select2-container--open .select2-selection {
    border-color: @telekomMagentaLight;
}

.market-percentage-input {
    width: 70px;
}

/** 
    CSSMap Plugin: additional color layer for the country enabled in db
*/

.cssmap-960 .eu1.enabled-region .bg {
    height: 43px;
    left: 486px;
    top: 616px;
    width: 20px
}

.cssmap-960 .eu1.enabled-region .bg {
    background-position: -1174px -2799px
}

.cssmap-960 .eu2.enabled-region .bg {
    height: 4px;
    left: 295px;
    top: 616px;
    width: 4px
}

.cssmap-960 .eu2.enabled-region .bg {
    background-position: -497px -2857px
}

.cssmap-960 .eu3.enabled-region .bg {
    height: 42px;
    left: 382px;
    top: 519px;
    width: 82px
}

.cssmap-960 .eu3.enabled-region .bg {
    background-position: -266px -2289px
}

.cssmap-960 .eu4.enabled-region .bg {
    height: 89px;
    left: 528px;
    top: 392px;
    width: 103px
}

.cssmap-960 .eu4.enabled-region .bg {
    background-position: -132px -2300px
}

.cssmap-960 .eu5.enabled-region .bg {
    height: 34px;
    left: 307px;
    top: 477px;
    width: 42px
}

.cssmap-960 .eu5.enabled-region .bg {
    background-position: -1180px -2352px
}

.cssmap-960 .eu6.enabled-region .bg {
    height: 43px;
    left: 448px;
    top: 577px;
    width: 42px
}

.cssmap-960 .eu6.enabled-region .bg {
    background-position: -475px -2220px
}

.cssmap-960 .eu7.enabled-region .bg {
    height: 44px;
    left: 519px;
    top: 593px;
    width: 67px
}

.cssmap-960 .eu7.enabled-region .bg {
    background-position: -666px -2221px
}

.cssmap-960 .eu8.enabled-region .bg {
    height: 62px;
    left: 425px;
    top: 558px;
    width: 63px
}

.cssmap-960 .eu8.enabled-region .bg {
    background-position: -339px -2368px
}

.cssmap-960 .eu9.enabled-region .bg {
    height: 15px;
    left: 625px;
    top: 712px;
    width: 25px
}

.cssmap-960 .eu9.enabled-region .bg {
    background-position: -1217px -2615px
}

.cssmap-960 .eu10.enabled-region .bg {
    height: 42px;
    left: 409px;
    top: 484px;
    width: 73px
}

.cssmap-960 .eu10.enabled-region .bg {
    background-position: -6px -2221px
}

.cssmap-960 .eu11.enabled-region .bg {
    height: 161px;
    left: 198px;
    top: 262px;
    width: 245px
}

.cssmap-960 .eu11.enabled-region .bg {
    background-position: -5px -2684px
}

.cssmap-960 .eu12.enabled-region .bg {
    height: 45px;
    left: 513px;
    top: 322px;
    width: 69px
}

.cssmap-960 .eu12.enabled-region .bg {
    background-position: -905px -2222px
}

.cssmap-960 .eu13.enabled-region .bg {
    height: 151px;
    left: 225px;
    top: 484px;
    width: 158px
}

.cssmap-960 .eu13.enabled-region .bg {
    background-position: -587px -2322px
}

.cssmap-960 .eu14.enabled-region .bg {
    height: 263px;
    left: 488px;
    top: 55px;
    width: 130px
}

.cssmap-960 .eu14.enabled-region .bg {
    background-position: -459px -2531px
}

.cssmap-960 .eu15.enabled-region .bg {
    height: 37px;
    left: 708px;
    top: 603px;
    width: 72px
}

.cssmap-960 .eu15.enabled-region .bg {
    background-position: -294px -2222px
}

.cssmap-960 .eu16.enabled-region .bg {
    height: 136px;
    left: 343px;
    top: 411px;
    width: 98px
}

.cssmap-960 .eu16.enabled-region .bg {
    background-position: -279px -2573px
}

.cssmap-960 .eu17.enabled-region .bg {
    height: 96px;
    left: 487px;
    top: 629px;
    width: 95px
}

.cssmap-960 .eu17.enabled-region .bg {
    background-position: -7px -2301px
}

.cssmap-960 .eu18.enabled-region .bg {
    height: 45px;
    left: 452px;
    top: 526px;
    width: 73px
}

.cssmap-960 .eu18.enabled-region .bg {
    background-position: -1003px -2222px
}

.cssmap-960 .eu19.enabled-region .bg {
    height: 81px;
    left: 18px;
    top: 158px;
    width: 119px
}

.cssmap-960 .eu19.enabled-region .bg {
    background-position: -273px -2462px
}

.cssmap-960 .eu20.enabled-region .bg {
    height: 71px;
    left: 168px;
    top: 407px;
    width: 48px
}

.cssmap-960 .eu20.enabled-region .bg {
    background-position: -587px -2221px
}

.cssmap-960 .eu21.enabled-region .bg {
    height: 1px;
    left: 414px;
    top: 598px;
    width: 2px
}

.cssmap-960 .eu21.enabled-region .bg {
    background-position: -568px -2858px
}

.cssmap-960 .eu22.enabled-region .bg {
    height: 166px;
    left: 351px;
    top: 550px;
    width: 128px
}

.cssmap-960 .eu22.enabled-region .bg {
    background-position: -1025px -2299px
}

.cssmap-960 .eu23.enabled-region .bg {
    height: 21px;
    left: 494px;
    top: 607px;
    width: 20px
}

.cssmap-960 .eu23.enabled-region .bg {
    background-position: -1220px -2510px
}

.cssmap-960 .eu24.enabled-region .bg {
    height: 47px;
    left: 504px;
    top: 354px;
    width: 78px
}

.cssmap-960 .eu24.enabled-region .bg {
    background-position: -1105px -2221px
}

.cssmap-960 .eu25.enabled-region .bg {
    height: 4px;
    left: 381px;
    top: 546px;
    width: 2px
}

.cssmap-960 .eu25.enabled-region .bg {
    background-position: -533px -2856px
}

.cssmap-960 .eu26.enabled-region .bg {
    height: 49px;
    left: 504px;
    top: 387px;
    width: 63px
}

.cssmap-960 .eu26.enabled-region .bg {
    background-position: -108px -2222px
}

.cssmap-960 .eu27.enabled-region .bg {
    height: 13px;
    left: 341px;
    top: 499px;
    width: 9px
}

.cssmap-960 .eu27.enabled-region .bg {
    background-position: -1218px -2467px
}

.cssmap-960 .eu28.enabled-region .bg {
    height: 22px;
    left: 499px;
    top: 620px;
    width: 28px
}

.cssmap-960 .eu28.enabled-region .bg {
    background-position: -1182px -2415px
}

.cssmap-960 .eu29.enabled-region .bg {
    height: 3px;
    left: 432px;
    top: 707px;
    width: 3px
}

.cssmap-960 .eu29.enabled-region .bg {
    background-position: -637px -2858px
}

.cssmap-960 .eu30.enabled-region .bg {
    height: 48px;
    left: 565px;
    top: 527px;
    width: 38px
}

.cssmap-960 .eu30.enabled-region .bg {
    background-position: -764px -2222px
}

.cssmap-960 .eu31.enabled-region .bg {
    height: 1px;
    left: 358px;
    top: 600px;
    width: 2px
}

.cssmap-960 .eu31.enabled-region .bg {
    background-position: -603px -2859px
}

.cssmap-960 .eu32.enabled-region .bg {
    height: 25px;
    left: 477px;
    top: 603px;
    width: 21px
}

.cssmap-960 .eu32.enabled-region .bg {
    background-position: -1220px -2561px
}

.cssmap-960 .eu33.enabled-region .bg {
    height: 49px;
    left: 314px;
    top: 441px;
    width: 44px
}

.cssmap-960 .eu33.enabled-region .bg {
    background-position: -377px -2288px
}

.cssmap-960 .eu34.enabled-region .bg {
    height: 337px;
    left: 330px;
    top: 20px;
    width: 283px
}

.cssmap-960 .eu34.enabled-region .bg {
    background-position: -794px -2494px
}

.cssmap-960 .eu35.enabled-region .bg {
    height: 102px;
    left: 431px;
    top: 417px;
    width: 108px
}

.cssmap-960 .eu35.enabled-region .bg {
    background-position: -6px -2426px
}

.cssmap-960 .eu36.enabled-region .bg {
    height: 72px;
    left: 179px;
    top: 623px;
    width: 35px
}

.cssmap-960 .eu36.enabled-region .bg {
    background-position: -272px -2359px
}

.cssmap-960 .eu37.enabled-region .bg {
    height: 72px;
    left: 497px;
    top: 531px;
    width: 101px
}

.cssmap-960 .eu37.enabled-region .bg {
    background-position: -141px -2420px
}

.cssmap-960 .eu38.enabled-region .bg {
    height: 652px;
    left: 491px;
    top: -14px;
    width: 465px
}

.cssmap-960 .eu38.enabled-region .bg {
    background-position: -1271px -2222px
}

.cssmap-960 .eu39.enabled-region .bg {
    height: 59px;
    left: 481px;
    top: 563px;
    width: 45px
}

.cssmap-960 .eu39.enabled-region .bg {
    background-position: -831px -2220px
}

.cssmap-960 .eu40.enabled-region .bg {
    height: 31px;
    left: 460px;
    top: 509px;
    width: 62px
}

.cssmap-960 .eu40.enabled-region .bg {
    background-position: -202px -2222px
}

.cssmap-960 .eu41.enabled-region .bg {
    height: 23px;
    left: 423px;
    top: 553px;
    width: 34px
}

.cssmap-960 .eu41.enabled-region .bg {
    background-position: -1181px -2300px
}

.cssmap-960 .eu42.enabled-region .bg {
    height: 119px;
    left: 181px;
    top: 600px;
    width: 146px
}

.cssmap-960 .eu42.enabled-region .bg {
    background-position: -280px -2739px
}

.cssmap-960 .eu43.enabled-region .bg {
    height: 322px;
    left: 397px;
    top: 87px;
    width: 141px
}

.cssmap-960 .eu43.enabled-region .bg {
    background-position: -621px -2503px
}

.cssmap-960 .eu44.enabled-region .bg {
    height: 31px;
    left: 344px;
    top: 538px;
    width: 49px
}

.cssmap-960 .eu44.enabled-region .bg {
    background-position: -396px -2221px
}

.cssmap-960 .eu45.enabled-region .bg {
    height: 87px;
    left: 554px;
    top: 624px;
    width: 205px
}

.cssmap-960 .eu45.enabled-region .bg {
    background-position: -6px -2557px
}

.cssmap-960 .eu46.enabled-region .bg {
    height: 131px;
    left: 517px;
    top: 462px;
    width: 194px
}

.cssmap-960 .eu46.enabled-region .bg {
    background-position: -792px -2310px
}

.cssmap-960 .eu47.enabled-region .bg {
    height: 207px;
    left: 192px;
    top: 296px;
    width: 107px
}

.cssmap-960 .eu47.enabled-region .bg {
    background-position: -450px -2291px
}

.cssmap-960 .eu48.enabled-region .bg {
    height: 106px;
    left: 219px;
    top: 397px;
    width: 80px
}

.cssmap-960 .eu48.enabled-region .bg {
    background-position: -1116px -2660px
}

.cssmap-960 .eu49.enabled-region .bg {
    height: 8px;
    left: 228px;
    top: 425px;
    width: 7px
}

.cssmap-960 .eu49.enabled-region .bg {
    background-position: -459px -2857px
}

.cssmap-960 .eu50.enabled-region .bg {
    height: 23px;
    left: 192px;
    top: 410px;
    width: 30px
}

.cssmap-960 .eu50.enabled-region .bg {
    background-position: -1213px -2246px
}

.cssmap-960 .eu51.enabled-region .bg {
    height: 125px;
    left: 199px;
    top: 296px;
    width: 73px
}

.cssmap-960 .eu51.enabled-region .bg {
    background-position: -1116px -2506px
}

.cssmap-960 .eu52.enabled-region .bg {
    height: 36px;
    left: 223px;
    top: 443px;
    width: 28px
}

.cssmap-960 .eu52.enabled-region .bg {
    background-position: -1116px -2806px
}

/** END OF CSSMap Plugin: additional color layer for the country enabled in db */

/**
    Overrides for JQuery ui controls
*/
.ui-widget-content {
    a {
        color: @telekomLink;

        &.btn-outline-primary {
            color: @telekomMagenta;

            &:hover, &:active, &:focus {
                color: white;
            }
        }
    }
}
/** END OF Overrides for JQuery ui controls */

// Workaround to center images in technology cards
.modal-html-content > .ui-wrapper {
    margin: auto !important;
}

.list-group-item-danger .field-validation-error {
    color: white;
}

// Remove default padding from bootstrap when opening technology cards
body.modal-open {
    padding-right: 0 !important;
    position: fixed;
    overflow-y: scroll;
    width: 100%;
}

// Battery Max Life Warning
.max-life-warning-container {
    display: flex;
    width: 100%;
    justify-content: center;

    .max-life-warning-box {
        display: block;
        box-shadow: 1px 1px 3px 1px lightgrey;
        max-width: 800px;

        .max-life-warning-button-container {
            display: block;
            text-align: right;
        }
    }
}

// Validation summary without list:

.validation-summary {
    ul {
        margin: 0;
        padding: 0;

        li {
            list-style: none;
        }
    }
}

// DevExtreme toolbars:

#adminToolbar, #messageListToolbar {
    &.dx-toolbar {
        .dx-button {
            box-shadow: 0px 0px 5px 2px @telekomGrey1;
            margin-right: 7px;

            &.dx-state-hover {
                box-shadow: 0px 0px 5px 2px @telekomMagenta;
            }
        }

        .dx-menu {
            .dx-menu-item {
                border-radius: 50%;
                box-shadow: 0px 0px 5px 2px #A4A4A4;
                min-width: 36px;
                height: 36px;
                font-size: 1rem;

                .dx-menu-item-content {
                    padding: 6px;
                    line-height: 0;
                    color: rgba(33, 37, 41, 0.54);
                }
            }
        }
    }
}

#HardwareComponentsList .dx-row a.add-hardware-button {
    .dx-button {
        box-shadow: 0px 0px 5px 2px @telekomGrey1;
        margin-right: 7px;
    }

    &:hover .dx-button {
        box-shadow: 0px 0px 5px 2px @telekomMagenta;
    }
}

h2.category-title {
    font-weight: bold;
    color: @telekomGrey5;
}

.data-events-error-message {
    .text-danger;
    margin-bottom: 12px;

    &.field-validation-valid {
        visibility: hidden;
    }
}

.data-events-config {
    input.form-control {
        min-width: 110px;
    }
}

.alert.field-validation-valid {
    width: 100%;
    visibility: hidden;
}

.alert.field-validation-error {
    width: 100%;
    color: white;
}

.vertical-centered-dropdown {
    span.select2-container {
        top: 50%;
        transform: translateY(-50%);
    }
}

// Used to have gaps inside of hyperlinks that are never underlined
.link-gap {
    &:before {
        content: '\00a0';
        display: inline-block;
    }

    &, &:link, &:visited, &:hover, &:focus, &:active {
        text-decoration: none;
    }
}

// Embedded PowerBI:
#embedContainer {
    min-width: 1000px;

    iframe {
        border: none;
    }
}

#biFullscreenButton {
    position: fixed;
    min-width: 80px;
}

// Customer Profile Card:
.customer-profile-card-dropdown {
    position: absolute;
    right: 1%;
    min-width: 210px;
    z-index: 10;
    margin-top: 5px;
    // Match with dropdown menu with button
    .dropdown-menu {
        button {
            min-width: 210px;
            padding-left: .75rem;
            cursor: pointer;
        }
    }
    // Dropdown arrow
    &::after {
        float: right;
        margin-top: 0.6rem;
    }
}

// Same cut-off point as quick access menu
@media (max-width: @quickAccessCutoff) {
    .customer-profile-card-dropdown {
        display: none;
    }
}

#CustomerProfileChartGrid {
    width: 100%;
    padding-left: 80px;
    padding-right: 80px;
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 15% 70% 15%;
    grid-template-columns: 15% 70% 15%;

    #CustomerProfileChart {
        -ms-grid-column: 2;
        grid-column: 2;
        -ms-grid-row: 2;
        grid-row: 2;
        position: relative;
        padding: 34px; // Used to restrict orb movement
        background-image: url("/images/customer-profile-card-background.png");
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        // Make the cell square (source: https://css-tricks.com/aspect-ratios-grid-items/)
        &::before {
            content: "";
            display: inline-block;
            width: 1px;
            height: 0;
            padding-bottom: calc(100% - 8px); // -8px to compensate for padding of parent
        }

        #CustomerProfileOrb {
            position: absolute;
            display: none;
            width: 1.2rem;
            height: 1.2rem;
            background-color: @telekomGrey5;
            border-radius: 50%;

            &:hover {
                cursor: pointer;
            }
        }
    }

    #CustomerProfileChartLeftLabel {
        -ms-grid-column: 1;
        grid-column: 1;
        -ms-grid-row: 2;
        grid-row: 2;
    }

    #CustomerProfileChartRightLabel {
        -ms-grid-column: 3;
        grid-column: 3;
        -ms-grid-row: 2;
        grid-row: 2;
    }

    #CustomerProfileChartTopLabel {
        -ms-grid-column: 2;
        grid-column: 2;
        -ms-grid-row: 1;
        grid-row: 1;
        width: 50%;
    }

    #CustomerProfileChartBottomLabel {
        -ms-grid-column: 2;
        grid-column: 2;
        -ms-grid-row: 3;
        grid-row: 3;
        width: 50%;
    }

    .CustomerProfileChartLabel {
        font-weight: bold;
        text-align: center;
        align-self: center;
        -ms-grid-row-align: center;
        justify-self: center;
        -ms-grid-column-align: center;
        font-size: 1.1rem;
        line-height: 1.2rem;
    }
}

.customer-profile-card-zone-text {
    display: none;
}

#user-menu-link {
    white-space: nowrap;
}

#language-selector-dropdown {
    > button {
        margin-top: 6px;
        margin-bottom: 6px;
        padding-top: 6px;
        padding-bottom: 6px;
    }

    button, .dropdown-menu {
        min-width: 100px;
    }
}

.hardware-nav-panel, .admin-nav-panel {
    .nav-bubble-list-item {
        margin-bottom: 1rem;

        .nav-bubble {
            margin-bottom: 0 !important;
        }

        .nav-text {
            line-height: normal !important;
        }
    }
}

// Print styles:
@media print {
    .not-print {
        display: none;
    }

    .always-print {
        display: inherit !important;
    }

    .avoid-page-break-inside {
        page-break-inside: avoid;
    }

    a.summary-nav {
        text-decoration: none;
    }
}

#panelDevices.hardware-nav-panel {
    a.nav-bubble-list-item .nav-text {
        font-size: 1rem;
        font-weight: bold;
    }

    ul.nav li a {
        min-width: 150px;
    }
}

.payload-step-container {
    display: flex;

    div:first-child {
        flex: 0 0 auto;
    }
}

.star-rating-container {
    .review-product-link-container {
        font-size: 0.65rem;
        line-height: 0.7rem;
        font-family: Arial,Helvetica,sans-serif;
    }
}

.product-rating, #RateProduct {
    .rate-base-layer,
    .rate-hover-layer,
    .rate-select-layer {
        color: @telekomMagenta;
    }
}
//Overrides for bootstrap tooltip
.tooltip {
    border-bottom: 0px;
    padding-bottom: 0px;
    padding-top: 0px;
    left: 30px !important;
    top: -5px !important;

    .tooltip-inner {
        background-color: #f6f6f6;
        border-color: #c5c5c5;
        outline-color: black;
        border: 1px solid #c5c5c5;
        padding: 0.4rem .5rem;
        color: black;
    }

    .arrow {
        display: none;
    }
}

.tooltip.show {
    opacity: 1;
}

.label-for-disabled-input {
    color: @colorLabelForDisabledInput;
}

textarea {
    overflow: hidden;
    min-height: 75px;
}

// Hardware Ecosystem
.hardware-ecosystem-page {
    .tile-header {
        display: none;
    }
}

.hardware-ecosystem {
    margin-top: -5px;
    margin-bottom: -5px;

    .tile {
        background-color: white;
        line-height: initial;
        margin-left: 0px;
        margin-right: 0px;
        margin-bottom: 30px;

        .btn {
            border-radius: 0;
        }

        a {
            text-decoration: none !important;
        }

        .tile-title a {
            color: inherit !important;
        }
    }

    .tile-picture {
        background-color: @telekomGrey3;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;

        a:not(.btn) {
            position: absolute;
            width: 100%;
            height: 100%;
        }
    }

    .tile-content {
        background-color: white;
        align-items: center;
        display: flex;
    }

    .tile-description {
        padding-bottom: 1rem;
        padding-top: 0.5rem;

        p {
            margin-bottom: 0;
        }
    }

    .tile-large {
        min-height: 420px;

        .tile-content {
            padding: 1.2rem;
        }
    }

    .tile-small {
        min-height: 265px;

        .tile-content {
            padding: 10px 2rem;
        }

        .tile-title {
            hyphens: auto;
        }

        .tile-description {
            font-size: 0.9rem;
        }
    }

    .main-menu {
        color: white;

        h2.tile-title {
            font-size: 2rem;
        }

        .tile-description p {
            margin-bottom: 0.5rem;

            br {
                content: "";
                margin-bottom: 0.5rem;
                display: block;
            }
        }
    }

    .main-menu-overview {
        background-color: black;

        p.overview-header {
            font-size: 1rem;
            font-weight: 700;
            margin-bottom: 0.5rem;
        }

        .overview-link {
            padding-bottom: 0.5rem;
            padding-top: 0.5rem;
        }

        .link-title {
            font-size: 1rem;
            /* color: @telekomMagenta;*/
            margin-bottom: 0.2rem;
            line-height: 1.2;
            font-weight: bold;
        }

        .link-description {
            font-size: 0.9rem;

            p {
                margin-bottom: 0;
            }
        }
    }

    .product-tile {
        .tile-picture {
            background-color: white;
            align-items: center;
            display: flex;
            background-size: 75%;
        }

        .tile-description {
            font-size: 1rem;
            font-weight: 700;
            padding-top: 0;
        }
    }

    .inline-block-center-vertically {
        display: inline-block;
        vertical-align: middle;
    }

    &.hardware-ecosystem-preview, &.product-tile-property, &.standard-tile-property {
        .tile a {
            color: @telekomMagenta !important;
        }

        .btn-outline-primary {
            color: @telekomMagenta !important;
            border-color: @telekomMagenta !important;

            &:hover {
                background: @telekomMagenta !important;
                border-color: @telekomMagenta !important;
                box-shadow: 0 0 5px rgba(0,0,0,0.3);
                color: white !important;
            }

            &:active, &:focus {
                border-color: @telekomMagenta !important;
                background: @telekomMagenta !important;
                color: white !important;
            }
        }

        .btn-primary {
            color: white !important;
            border-color: @telekomMagenta !important;
            background: @telekomMagenta !important;

            &:hover {
                color: @telekomMagenta !important;
                border-color: @telekomMagenta !important;
                background: white !important;
            }

            &:active, &:focus, &:not(:disabled):not(.disabled):active {
                border-color: @telekomMagenta !important;
                background: white !important;
                color: @telekomMagenta !important;
            }
        }
    }

    .lets-talk {

        h1 {
            font-size: 2rem;
            border-bottom: none;
            padding-bottom: 0;
            color: @telekomMagenta !important;
        }
    }
}

@import "_responsivity.less";

@media screen and (max-width: 991px) {
    html {
        body {
            /*pop up modals*/
            main > div.modal,
            div#modalTechnologyCard, 
            div#modalTechnologyCard,
            div#modalBenefitOfTelekom {
                position: fixed;
                top: 50% !important;
                left: 50% !important;
                transform: translate(-50%, -50%);
                width: 100% !important;
                height: 100%;
                padding: 0 !important;
                margin: 0;
                display: flex !important;
                justify-content: center;
                align-items: center;

                > div.modal-dialog {
                    max-height: 95%;

                    > div.modal-content {
                        min-width: 250px;

                        /*checkbox and label text*/
                        label.moc-hsg-checkbox-label {
                            display: flex;
                            align-items: center;
                            font-size: 95%;
                            text-align: left;
                        }
                    }
                }
            }
            /*fix modal display issue: without this solution, the div.modal would always show and overlay other content, when it should be hidden*/
            main > div.modal:not(.show),
            div#modalTechnologyCard:not(.show),
            div#modalBenefitOfTelekom:not(.show) {
                display: none !important;
            }
        }
    }
}

@media screen and (max-width: 768px) {
    html {
        body {
            table.chart-info {
                width: 100%;
                min-width: 0px;
                margin-bottom: 15px;
            }

            table.chart-info td:nth-child(1), 
            table.chart-info th:nth-child(1) {
                min-width: 115px;
            }

            table.chart-info td:nth-child(2), 
            table.chart-info td:nth-child(3), 
            table.chart-info th:nth-child(2), 
            table.chart-info th:nth-child(3) {
                min-width: 200px;
            }

            table.chart-info td:nth-child(2), 
            table.chart-info th:nth-child(2) {
                border-right: none;
            }
        }
    }
}


/* font resizing for mobile screens */ 
@media screen and (max-width: 576px) {
    :root {
        font-size: 90% !important;
    }
}


/*fix popups on screens less than 500px high*/
@media screen and (max-height: 500px) {
    html {
        body {
            div#modalAntennaSelectionGuide,
            div#modalBenefitOfTelekom,
            div#modalTechnologyCard {
                > div.modal-dialog {
                    height: 95vh;

                    > div.modal-content {
                        height: 100%;

                        > div.modal-description {
                            margin: 10px 1rem;
                        }

                        > div.modal-body {
                            max-height: 100% !important;
                            height: 100%;

                            > form {
                                height: 100%;

                                div.modal-html-content {
                                    height: fit-content;
                                    padding-top: 5px;
                                    padding-bottom: 5px;

                                    > h3 {
                                        padding: 0;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

.fix-grid-padding {
    //padding-right: 14px!important;
    overflow: visible!important;
}

.fix-error-color {
    color: #D90000 !important;
    font-family: 'TeleGroteskNext', 'Roboto', 'RobotoFallback', 'Helvetica', 'Arial', sans-serif;
}

.dx-invalid-message {
    z-index: 1;
}

.scrollable-textarea {
    overflow-y: auto;
}

.rule-met {
    color: green;
}

.rule-met:before {
    position: relative;
    left: -5px;
    content: "✔";
}


.rule-not-met {
    color: #D90000;
}

.rule-not-met:before {
    position: relative;
    left: -5px;
    content: "✖";
}

.password-message-item {
    font-size: 0.8rem
}

.no-validation-message .dx-invalid-message-content {
    display: none;
}

