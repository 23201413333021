﻿@import "../_mixins.less";

#panelPartners {
    min-height: 300px;

    h2 {
        margin: 3rem 0rem 0rem 0rem;
    }

    .img-container {
        width: 65%;
        height: 90px;
        margin: 1rem auto 1rem auto;
        position: relative;

        img.center {
            position: absolute;
            margin: auto;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
        }

        img.wide {
            width: 100%;
            height: auto;
        }

        img.tall {
            height: 100%;
            width: auto;
        }
    }
}
    