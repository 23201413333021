﻿@import "_mixins";

#Navigation .navigationPanel();
#Loader .main();

section.header-container {
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 1010; 
    transition: top .3s;

    header {
        position: sticky;
        top: 0;

        h1 {
            border-bottom: none;
        }

        &:first-of-type {
            z-index: 1011;
        }

        &.second-header {
            top: 70px;
        }

        nav.navbar {
            /*top navbar*/
            &.bg-dark {
                flex-flow: row;

                .navbar-right {
                    display: flex;

                    ul.navbar-nav {
                        align-items: center;
                        display: flex !important;

                        /*get started button*/
                        li #get-started-button {
                            margin: 0 10px;
                        }

                        /*account and language select buttons and dropdowns*/
                        li.nav-item {
                            button.btn-outline-primary, button.btn-outline-primary:hover,
                            button.btn-outline-primary:focus, button.btn-outline-primary:active,
                            a.nav-link, a.nav-link:hover, a.nav-link:focus, a.nav-link:active {
                                border: none;
                                text-decoration: none;
                                box-shadow: none !important;
                                background: none !important;

                                &#user-menu-link {
                                    margin: 6px 10px;
                                    padding-top: 6px;
                                    padding-bottom: 6px;
                                }
                            }

                            > button#user-menu-link {
                                min-width: 0!important;
                                margin-left: 5px!important;
                                margin-right: 5px!important;
                            }

                            > div.dropdown-menu-right {
                                position: absolute !important;
                                max-height: 80vh;
                                overflow-y: scroll;
                            }

                            > div#language-selector-dropdown {
                                > button {
                                    min-width: 0!important;
                                    margin-left: 5px!important;
                                    margin-right: 5px!important;
                                }

                                > div.dropdown-menu {
                                    position: absolute !important;
                                    left: -25px;
                                    max-height: 80vh;
                                    overflow-y: scroll;
                                }
                            }
                        }
                    } 
                }
            }

            /*second nav, main navigation*/
            &#main-navbar {
                display: flex;
                flex-direction: column;

                #main-navlist {
                    li.nav-item {
                        display: flex;
                        align-items: center;
                        padding: 0.5rem;

                        &:first-of-type {
                            padding-left: 0;
                        }

                        #navbar-label {
                            font-weight: bold;
                        }

                        /* bootstrap fix, so the nav works on mobile and pc (click, hover, tap events) */
                        &.dropdown {
                            button.dropdown-toggle {
                                width: 100%;
                                border: none;
                                background: none;
                                display: flex;
                                justify-content: flex-end;
                                align-items: center;

                                > span {
                                    border: solid #000;
                                    border-width: 0 1px 1px 0;
                                    display: inline-block;
                                    padding: 3px;
                                    transform: rotate(45deg);
                                    vertical-align: 0.255em;
                                    margin-bottom: 5px;
                                    content: "";
                                }

                                &::after {
                                    display: none;
                                }

                                &:focus {
                                    outline: none;
                                }
                            }
                        }

                        &.dropdown:hover button > span {
                            transition: all .1s;
                            transform: rotate(225deg);
                            vertical-align: 0;
                            margin-bottom: 0;
                        }
                    }
                }
            }

            button.navbar-toggler {
                padding: 0;
                margin-left: 0.75rem;
                border-color: transparent !important;
            }

            button.navbar-toggler:focus {
                outline: none !important;
            }
        }
    }
}



/*

    Media Queries

*/
@media (min-width: 979px) {
    // Show dropdown menu on hover, not on click
    #main-navlist li.dropdown:hover > div.dropdown-menu {
        display: block;
    }
}


@media screen and (max-width: 991px) {
    html {
        body {
            section.header-container {
                header.second-header {
                    nav {
                        &#main-navbar {
                            .navbar-collapse {
                                justify-content: center;
                            }

                            #main-navlist {
                                padding: 0;
                                margin: 0 0 15px;
                                display: flex;
                                flex-direction: column;
                                align-items: center;

                                li.nav-item {
                                    width: 90%;
                                    padding: 0px !important;
                                    margin: 5px 0;

                                    &.dropdown:hover button > span {
                                        transform: rotate(45deg) !important;
                                        vertical-align: 0.255em;
                                        margin-bottom: 5px;
                                    }

                                    div.dropdown-menu {
                                        left: inherit;
                                        right: 0;
                                        position: absolute;
                                        min-height: 150px;
                                        max-height: 75vh;
                                        overflow-y: scroll;

                                        .dropdown-item {
                                            padding: 5px 10px;
                                            margin: 5px 0;
                                        }
                                    }
                                }

                                .btn-primary#get-started-button {
                                    width: 90%;
                                }

                                .dropdown-menu {
                                    max-height: 50vh;
                                    max-width: 85vw;
                                    overflow-y: scroll;
                                    margin: 0;
                                    padding: 0;

                                    .dropdown-item {
                                        white-space: break-spaces;
                                    }
                                }
                            }
                        }

                        #language-selector-dropdown .dropdown-nenu,
                        #language-selector-dropdown button,
                        button#user-menu-link {
                            min-width: 0px !important;
                            margin-left: 5px !important;
                            margin-right: 5px !important;
                        }

                        #language-selector-dropdown .dropdown-menu,
                        .navbar-nav .dropdown-menu {
                            position: absolute;
                            left: -35px;
                            max-height: 75vh;
                            overflow-y: scroll;

                            .dropdown-item {
                                padding: 5px 10px;
                                margin: 5px 0;
                            }
                        }

                        .navbar-nav {
                            margin-bottom: 0 !important;

                            .dropdown-menu,
                            #user-menu-link {
                                left: 0;
                            }
                        }
                    }

                    .main-header {
                        h1 {
                            font-size: 1.6rem;
                        }
                    }
                }
            }
        }
    }
}


/*Mobile devices input landscape mode*/
@media screen and (max-height: 500px) {
    html {
        body {
            section.header-container {
                header.second-header {
                    nav#main-navbar {
                        max-height: calc(100vh - 70px);
                        overflow-y: scroll;
                    
                        div.show:not(.dropdown-menu) {
                            height: 100%;
                            transition: all 0s !important;
                        }

                        div.navbar-collapse {
                            > ul > li > a {
                                padding-top: 5px;
                                padding-bottom: 5px;
                                word-break: keep-all;
                            }

                            > div > #get-started-button {
                                margin-bottom: 15px;
                            }
                        }
                    }

                    .main-header h1 {
                        padding-top: 0;
                        padding-bottom: 0;
                    }
                }
            }
        }
    }
}