﻿@import "../_mixins.less";

#SectionMaster .main();

#SectionMaster .device-catalog();

#Accordion .main();

.switch-buttons {
    padding: 0 !important;
    margin-top: 20px;

    a, button {
        margin: 10px 0 !important;
        min-width: 80px;

        @media (min-width: 768px) {
            min-width: 90px;
        }

        @media (min-width: 1024px) {
            min-width: 125px;
        }
    }
}

#panelAccordionChipsets,
#panelAccordionModules,
#panelAccordionBatteries,
#panelAccordionAntennaSolutions,
#panelAccordionApplications,
#panelAccordionPositioningSolutions {
    .hardware-select-button, .battery-select-button {
        display: none;
    }

    .btn {
        min-width: 120px;
        font-size: medium;
    }
}

#panelAccordionApplications {
    .performance-button {
        /*margin-top: 0.5rem;*/
    }

    .selling-points {
        padding-left: 0;
        padding-right: 1.5rem;
    }
}

#panelAccordionChipsets,
#panelAccordionModules {
    .performance-button {
        display: none;
    }
}

#panelAccordionBatteries {
    .accordion-content, .input-group-text {
        font-size: medium;
    }

    .accordion-title-description {
        font-size: large;
    }

    .col-form-label {
        padding-right: 0px;
    }
}