﻿@import "../_mixins.less";

#SectionMaster .main();

#SectionMaster .device-catalog();

#panelDevices {
    padding: 0 !important;

    h1, h2 {
        margin: 1rem 0rem 1rem 0rem;
    }

    ul.nav {
        li {
            padding: 0 !important;

            a {
                min-width: 130px;
            }
        }
    }
}

a {
    .people {
        font-size: 40px;
        padding-top: 0px;
        color: black;

        &.active {
            color: @telekomMagentaActive;
        }

        @media (min-width: 768px) {
            font-size: 50px;
            padding-top: 7px;
        }
    }
}

div.left-nav-bubble {
    text-align: center;
}

.switch-buttons {
    padding: 0 !important;
    margin-top: 20px;

    a, button {
        margin: 10px 0 !important;
        min-width: 80px;

        @media (min-width: 768px) {
            min-width: 90px;
        }

        @media (min-width: 1024px) {
            min-width: 125px;
        }
    }
}

.fileuploader-container {
    border: 1px solid #d3d3d3;
    border-radius: .25rem;
    padding: .375rem .75rem;
}

.dx-fileuploader-container {
    .dx-fileuploader-button {
        height: 40px;
    }

    .dx-fileuploader-file-container {
        height: 50px;
    }

    .dx-fileuploader-file {
        line-height: 16px;
    }
}

.invitation-popup-content {
    .input-row {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        align-items: baseline;

        input[type=text] {
            width: 80%;
            border-radius: 4px;
            border: 1px solid lightgray;
            box-shadow: 1px 2px 4px grey;
            min-height: 25px;
            padding: 4px;
        }

        &.error-row {
            margin: 10px 0px;
        }
    }

    .invitation-popup-buttons {
        margin-top: 0.5rem;
        text-align: right;
    }
}

.dx-tab-content, .dx-tab-text {
    text-transform: none;
}

.man-option {           
    align-items: center;
}

.man-logo-container {
    position: relative;
    display: inline-block;
    width: 125px;
}

.man-logo {
    max-height: 30px;
    max-width: 125px;
    box-shadow: 0px 0px 3px 1px #7f7f7f;
}

#selectManufacturer {
    .select2 {
        .selection {
            .man-option {
                position: absolute;
            }

            .select2-selection {
                padding-top: 5px;
                padding-bottom: 5px;
                height: 42px;
                line-height: 30px;
            }
        }
    }
}

table {
    &.property-table {

        td {
            vertical-align: middle;
        }

        th:nth-child(1), td:nth-child(1),
        th:nth-child(2), td:nth-child(2) {
            width: 18%;
            max-width: 120px;
        }

        td:nth-child(1), td:nth-child(2) {
            font-size: 0.8rem;
            word-break: break-word;
        }

        th:nth-child(3), td:nth-child(3),
        th:nth-child(4), td:nth-child(4),
        th:nth-child(5), td:nth-child(5) {
            width: 18%;
            min-width: 140px;
        }

        th:last-child, td:last-child {
            width: 10%;
            max-width: 80px;
        }
    }

    .add-json-value-button, .add-json-string-value-button, {
        min-width: 0 !important;
        float: right;
        margin-top: 3px;

        &:hover {
            color: @telekomMagenta;
            text-decoration: none;
        }
    }
}

#HTMLEditor2 {
    min-height: 410px;
}

#HTMLEditorIncludesText, #HTMLEditorBenefitsText, #HTMLEditorAddOnsText {
    min-height: 200px;
}

#HTMLEditorAddOnsText {
    a, a:hover {
        color: #1bada2;
    }
}

// Preview of nav bubble for editing services/learn icons
#PreviewBubble {
    display: inline-block;
    height: 90px;
    width: 90px;
    border-width: 3px;
    border-style: solid;
    border-radius: 50%;
    border-color: @telekomGrey5;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 75% auto;
    margin-bottom: 10px;

    &:hover, &:active, &:focus {
        border-color: @telekomMagenta;
    }
}

#new-company-container {
    display: none;
}

.hardware-partner-logo {
    max-height: 60px;
    box-shadow: 0px 0px 1px 0px #7f7f7f;
}

#HardwarePartnerLogoDataGrid .dx-datagrid-focus-overlay {
    visibility: hidden !important;
}
