﻿@import "../_mixins.less";

#SectionMaster .main();
#SectionMaster .device-catalog();

#panelManageProjects {
    padding: 0 !important;

    ul.nav {
        justify-content: center;
    }

    li {
        padding: 30px !important;

        a {
            min-width: 180px;
        }
    }

    .nav-bubble {
        background-color: #F2F2F2;
    }
}

.separator {
    display: block;
    width: 100%;
    height: 1.5rem;
}

#iotSolutionOptimizerIcon {
    width: 45px;  
    margin-right: 15px;
}